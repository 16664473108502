export const DOMAIN_URI = 'https://backend.dev.smsaftercare.dnmiss.com';
export const APP_URI = 'https://dev.smsaftercare.dnmiss.com';

// export const DOMAIN_URI = 'http://sms-aftercare.loc';
// export const APP_URI = 'http://localhost:3000';

export const SSO_URI = 'https://sso-dev.j3techsolution.com';
export const API_URI = `${DOMAIN_URI}/api/`;
export const APP_KEY = '961f0677028c6c7b491527da49866c';

export const SSO_LOGOUT = `${SSO_URI}:8443/realms/J3Tech-SSO/protocol/openid-connect/logout?client_redirect_uri=${APP_URI}/sign-in&platformId=5`;
