import React, {FC} from 'react';
import {Search, Title} from "./styled";

type Props = {
    children: React.ReactNode;
    title?: string;
};

const SearchPanel: FC<Props> = (props) => {

    const {title = ''} = props;

    return (
        <>
            <Title>{title}</Title>
            <Search>
                {props.children}
            </Search>
        </>
    );
};

export default SearchPanel;
