import React, {FC} from 'react';
import Header from "../Header/Header";
import {PageContainer, Wrapper} from './styled'

type Props = {
    children: React.ReactNode;
};

const MainLayout: FC<Props> = (props) => {
    return (
        <Wrapper>
            <Header/>
            <PageContainer>
                {props.children}
            </PageContainer>
        </Wrapper>
    );
};

export default MainLayout;
