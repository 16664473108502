import React, {FC} from 'react';
import {InputProps} from './Button';
import ArrowLeft from '../../../assets/images/icons/arrow-left.svg';
import {BackButtonStyled} from "./styled";

const BackButton: FC<InputProps> = ({color = 'green', ...props}) => {
    return (
        <BackButtonStyled
            color={'transparent'}
            {...props}
        >
            <img src={ArrowLeft} alt={'Back'}/>
            BACK
        </BackButtonStyled>
    );
};

export default BackButton;
