import React, {FC} from 'react';
import moment from "moment/moment";
import avatarRobot from "../../../../assets/images/icons/lightning.svg";
import avatarAdmin from "../../../../assets/images/icons/crown.svg";
import avatarCustomer from "../../../../assets/images/icons/star.svg";
import {
    MessageAvatar,
    MessageBody,
    MessageBodyFooter,
    MessageBodyText,
    MessageIncome,
    MessageOutcome, MessageOutcomeError
} from "../styled";

type Props = {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    type: "inbound"|"outbound";
    owner: "system"|"admin"|"customer";
    sendStatus: string;
    message: string;
    footerText?: string;
    messageAt: string;
};

const Message: FC<Props> = ({onClick, type, owner, sendStatus, message,  footerText, messageAt, ...props}) => {

    //['new', 'accepted', 'queued', 'sending', 'sent', 'failed', 'delivered', 'undelivered', 'receiving', 'received', 'error'];
    const failedStatuses = ['failed', 'undelivered', 'error'];
    const Avatar = (owner === 'system') ? avatarRobot : (owner === 'admin' ? avatarAdmin : avatarCustomer);

    const Item = (type === 'inbound') ? MessageIncome
        : (failedStatuses.includes(sendStatus) ? MessageOutcomeError : MessageOutcome);

    return (
        <Item onClick={onClick}>
            <MessageBody>
                <MessageBodyText>
                    {message}
                </MessageBodyText>
                <MessageBodyFooter>
                    <div>{`${footerText ? footerText+' - ' : ''}`}{moment.utc(messageAt).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:mm A')}</div>
                    <div>{type==='outbound' ? sendStatus : ''}</div>
                </MessageBodyFooter>
            </MessageBody>
            <MessageAvatar src={Avatar} alt={''}/>
        </Item>
    );
};

export default Message;
