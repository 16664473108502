import axios from 'axios';
import {API_URI, APP_KEY, SSO_LOGOUT} from '../constants/api';
import {store} from '../store/store';
import {resetTokens} from "../store/auth/authSlice";
import {showErrorAlert} from "../store/alert/alertSlice";

const configureApi = () => {

    let refreshingToken:any = null;

    function refreshToken() {
        const refresh_token = localStorage.getItem('refresh_token');
        return instance.post("auth/refresh-token", {refresh_token});
    }

    const instance = axios.create({
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            'X-Requested-With': 'XMLHttpRequest',
            'App-Key': APP_KEY,
        },
        baseURL: API_URI,
    });

    instance.interceptors.request.use(function (config) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken && config.headers) {
            config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
        }
        return config;
    });

    instance.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const config = error.config;
        if (error.response && error.response.status === 401 && !config._retry) {
            config._retry = true;
            try {
                refreshingToken = refreshingToken ? refreshingToken : refreshToken();
                const res = await refreshingToken;
                refreshingToken = null;
                if(res.data.data.resource.access_token) {
                    const {access_token, refresh_token} = res.data.data.resource;
                    localStorage.setItem('access_token', access_token);
                    localStorage.setItem('refresh_token', refresh_token);
                }
                return instance(config);
            } catch (error) {
                store.dispatch(resetTokens());
                window.location.replace('/sign-in');
            }
        } else if (error.response && error.response.status === 400) {
            if (error.config.url !== 'auth/refresh-token' && error.config.url !== 'auth/logout' && !error.config.url.includes('/messages-update')) {
                store.dispatch(showErrorAlert({
                    message: error.response?.data?.message,
                    errors: error.response?.data?.errors
                }));
            }
        } else if (error.response && error.response.status === 403) {
            store.dispatch(showErrorAlert({
                message: error.response?.data?.message,
                errors: error.response?.data?.errors,
                cb: () => window.open(SSO_LOGOUT, "_self")
            }));
        } else {
            if (!error.config.url.includes('/messages-update')) {
                store.dispatch(showErrorAlert({
                    message: error.response?.data?.message,
                }));
            }
        }
        return Promise.reject(error)
    });

    return instance;
};

const api = configureApi();
export default api;
