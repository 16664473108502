import React, {FC} from 'react';
import {Wrapper} from './styled'

type Props = {
    children: React.ReactNode;
};

const PageContentContainer: FC<Props> = (props) => {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
};

export default PageContentContainer;
