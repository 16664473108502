import styled from "styled-components";
import background from "../../../assets/images/signin-background.jpg"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(145.5deg, rgba(50, 48, 48, 0.80) 16.76%, rgba(23, 22, 22, 0.80) 72.74%), url(${background});
  @media screen and (max-width: 1068px) {
    background-size: auto 100%; 
  }
`;