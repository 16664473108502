import {configureStore} from '@reduxjs/toolkit';
import generalReducer from './general/generalSlice';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import customerSlice from './customer/customerSlice';
import importSlice from './import/importSlice';
import alertSlice from './alert/alertSlice';
import smsSlice from './sms/smsSlice';
import smsActivitySlice from './smsActivity/smsActivitySlice';
import campaignSlice from './campaign/campaignSlice';

export const store = configureStore({
    reducer: {
        general: generalReducer,
        auth: authReducer,
        user: userReducer,
        customer: customerSlice,
        import: importSlice,
        alert: alertSlice,
        sms: smsSlice,
        smsActivity: smsActivitySlice,
        campaign: campaignSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
