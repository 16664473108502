import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import App from './App';
import {GlobalStyle} from './GlobalStyle'
import './assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
                <GlobalStyle />
                <App/>
            </QueryParamProvider>
        </BrowserRouter>
    </Provider>
);
