import React, {FC, InputHTMLAttributes} from 'react';
import {StyledButton} from "./styled";

export interface InputProps extends InputHTMLAttributes<HTMLElement> {
    title?: any;
    color?: string;
    width?: number;
    height?: number;
}

const Button: FC<InputProps> = ({title = null, color = 'green', width = 320, height = 36, type = 'button', ...props}) => {
    return (
        <StyledButton
            color={color}
            width={width}
            height={height}
            type={type}
            {...props}
        >
            {title}
        </StyledButton>
    );
};

export default Button;
