import styled, {ThemeProps} from "styled-components";

interface ContainerProps {
    color: string;
}

const getBtnColor = () => (props: ContainerProps & ThemeProps<any>) => {
    switch (props.color) {
        case 'green' :
            return {
                backgroundColor: props.theme.buttonGreen
            }
        case 'red' :
            return {
                backgroundColor: props.theme.buttonRed
            }
        case 'yellow' :
            return {
                backgroundColor: props.theme.buttonYellow,
            }
        case 'transparent' :
            return {
                backgroundColor: props.theme.whiteColor,
                border: `1px solid ${props.theme.greenColor}`,
                color: props.theme.greenColor,
                fontWeight: '700',
                fontSize: '12px',
            }
    }
}

export const StyledButton = styled.button<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  ${getBtnColor()}

  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  &:hover,
  &:active {
    filter: brightness(110%);
  }

  &:disabled {
    filter: brightness(80%);
  }
`;

export const BackButtonStyled = styled(StyledButton)`
    width: 100px;
    &>img {
      margin-right: 4px;
    }
`;
