import React, {FC, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchStates} from "../../../store/general/generalSlice";
import {fetchCustomerById, fetchUpdateCustomer} from "../../../store/customer/customerSlice";
import {PageContent, PageContentContainer, PageHeader, Spinner} from "../../common";
import {Button, BackButton, Datepicker, Input} from "../../controls";
import {ROUTES} from "../../../router/routes";
import {CustomerFormProps} from "../../../types";
import {emailPattern, zipPattern} from "../../../helpers/validationRules";
import RecentMessages from "./parts/RecentMessages";
import {
    Form,
    PageGroup,
    Title,
    CustomerName,
    FormLabel,
    FormGroupRow,
    FormGroupTitle,
    FormInput,
    FormGroupCol,
    FormButton,
    BtnWrapper,
    RightBtnsWrapper,
    FormGroup,
    FormSelect,
    FormInputMask,
    HoverText
} from "./styled";

const languages = [
    {value: 'english', label: 'English'},
    {value: 'spanish', label: 'Spanish'},
    {value: 'tagalog', label: 'Tagalog'},
    {value: 'vietnamese', label: 'Vietnamese'},
    {value: 'french', label: 'French'},
    {value: 'arabic', label: 'Arabic'},
    {value: 'korean', label: 'Korean'},
    {value: 'german', label: 'German'},
]

const Customer: FC = () => {

    const navigate = useNavigate();
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const {states} = useAppSelector(state => state.general);
    const {customer, isLoading} = useAppSelector(state => state.customer);
    const [statesOptions, setStatesOptions] = useState([{value: '', label: ''}]);
    const [isHover, setIsHover] = useState(false);
    const {control, handleSubmit, reset, formState: {errors}} = useForm<CustomerFormProps>({
        mode: 'onBlur',
        defaultValues: {
            customer_first_name: '',
            customer_last_name: '',
            deceased_first_name: '',
            deceased_last_name: '',
            language: '',
            company: '',
            director: '',
            dob: null,
            dod: null,
            funeral_date: null,
            relationship: '',
            address: '',
            address2: '',
            city: '',
            state_id: '',
            zip: '',
            apartment: '',
            cell_phone: '',
            email: '',
            case_type: '',
        }
    });

    const convertUTCToLocalDate = (date: any) => {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
        return date
    }

    const convertLocalToUTCDate = (date: any) => {
        if (!date) {
            return date
        }
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    useEffect(() => {
        reset({
            customer_first_name: customer?.customer.name || '',
            customer_last_name: customer?.customer.last_name || '',
            language: customer?.customer.language || '',
            deceased_first_name: customer?.name || '',
            deceased_last_name: customer?.last_name || '',
            company: customer?.company+' - '+customer?.company_location,
            director: customer?.director || '',
            relationship: customer?.relationship || '',
            case_type: customer?.case_type?.name || '',
            //dob: customer?.dob ? new Date(customer?.dob) : null,
            //dod: customer?.dod ? new Date(customer?.dod) : null,
            //funeral_date: customer?.funeral_date ? new Date(customer?.funeral_date) : null,
            dob: customer?.dob ? convertUTCToLocalDate(customer?.dob) : null,
            dod: customer?.dod ? convertUTCToLocalDate(customer?.dod) : null,
            funeral_date: customer?.funeral_date ? convertUTCToLocalDate(customer?.funeral_date) : null,
            address: customer?.customer.address.address || '',
            address2: customer?.customer.address.address2 || '',
            city: customer?.customer.address.city || '',
            state_id: customer?.customer.address.state_id || '',
            zip: customer?.customer.address.zip || '',
            apartment: customer?.customer.address.apartment || '',
            cell_phone: customer?.customer.address.cell_phone || '',
            email: customer?.customer.address.email || '',
        });
    }, [customer]);

    useEffect(() => {
        dispatch(fetchStates());
        if (id) dispatch(fetchCustomerById(id));
    }, [id]);

    useEffect(() => {
        setStatesOptions(states.map((item) => ({
            value: item.id,
            label: item.code,
        })))
    }, [states]);

    const navToList = () => {
        navigate(ROUTES.CUSTOMERS, {state: {keepFilters: false}});
    }

    const handleOnSubmit = (data: any) => {
        if (id) {
            dispatch(fetchUpdateCustomer({id, data, cb: navToList}));
        }
    };

    return (
        <PageContentContainer>
            <PageHeader>
                <Spinner isLoading={isLoading}/>
                <BtnWrapper>
                    <BackButton onClick={()=>navigate(ROUTES.CUSTOMERS, {state: {keepFilters: true}})}/>
                    <RightBtnsWrapper>
                        <Button
                            title={'CUSTOMER DETAILS'}
                            color={'yellow'}
                            width={164}
                        />
                        <Button
                            onClick={()=>navigate(`/sms-panel/${customer?.id || 0}`)}
                            title={'MESSAGES'}
                            color={'transparent'}
                            width={164}
                        />
                    </RightBtnsWrapper>
                </BtnWrapper>
            </PageHeader>
            <PageContent>
                <PageGroup>
                    <Form>
                        <Title>Customer Details</Title>
                        <CustomerName>{`${customer?.customer?.name || ''} ${customer?.customer?.last_name || ''}` || ''}</CustomerName>
                        <FormGroup>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Language</FormLabel>
                                    <Controller
                                        name={"language"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormSelect
                                                {...field}
                                                name={'language'}
                                                placeholder={'Language'}
                                                value={field.value}
                                                options={languages}
                                                error={errors.language}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Location</FormLabel>
                                    <FormInput
                                        name={'location'}
                                        value={customer?.full_location || ''}
                                        disabled
                                        readOnly
                                        inputSize={'xm'}
                                        onMouseOver={()=>setIsHover(true)}
                                        onMouseLeave={()=>setIsHover(false)}
                                    />
                                    {isHover && customer?.full_location.length && (
                                        <HoverText>{customer?.full_location}</HoverText>
                                    )}
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>First Name</FormLabel>
                                    <Controller
                                        name={"customer_first_name"}
                                        control={control}
                                        rules={{required: "This field is required"}}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Customer First Name'}
                                                error={errors.customer_first_name}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Last Name</FormLabel>
                                    <Controller
                                        name={"customer_last_name"}
                                        control={control}
                                        rules={{required: "This field is required"}}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Customer Last Name'}
                                                error={errors.customer_last_name}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Director</FormLabel>
                                    <Controller
                                        name={"director"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Director Name'}
                                                error={errors.director}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Case Type</FormLabel>
                                    <Controller
                                        name={"case_type"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Case Type'}
                                                error={errors.case_type}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                        </FormGroup>

                        {/*/////////////////////////////*/}

                        <FormGroup>
                            <FormGroupTitle>Contact information</FormGroupTitle>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Phone Number</FormLabel>
                                    <Controller
                                        name={"cell_phone"}
                                        control={control}
                                        rules={{required: "This field is required"}}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInputMask
                                                mask={'(+1) 999 999 9999'}
                                                error={errors.cell_phone}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Email Address</FormLabel>
                                    <Controller
                                        name={"email"}
                                        control={control}
                                        rules={{
                                            required: "This field is required",
                                            pattern: emailPattern,
                                        }}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Email'}
                                                error={errors.email}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                        </FormGroup>

                        {/*///////////////////////////*/}

                        <FormGroup>
                            <FormGroupTitle>Deceased Information</FormGroupTitle>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>First Name</FormLabel>
                                    <Controller
                                        name={"deceased_first_name"}
                                        control={control}
                                        rules={{required: "This field is required"}}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Deceased First Name'}
                                                error={errors.deceased_first_name}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Last Name</FormLabel>
                                    <Controller
                                        name={"deceased_last_name"}
                                        control={control}
                                        rules={{required: "This field is required"}}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Deceased Last Name'}
                                                error={errors.deceased_last_name}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Funeral Date</FormLabel>
                                    <Controller
                                        name={"funeral_date"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <Datepicker
                                                maxDate={new Date()}
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                inputSize={'xm'}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Birth Date</FormLabel>
                                    <Controller
                                        name={"dob"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <Datepicker
                                                maxDate={new Date()}
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                inputSize={'xm'}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Death Date</FormLabel>
                                    <Controller
                                        name={"dod"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <Datepicker
                                                maxDate={new Date()}
                                                onChange={(date) => field.onChange(date)}
                                                selected={field.value}
                                                inputSize={'xm'}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Relationship on Deceased</FormLabel>
                                    <Controller
                                        name={"relationship"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Relationship'}
                                                error={errors.relationship}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                        </FormGroup>

                        {/*/////////////////////////////*/}

                        <FormGroup>
                            <FormGroupTitle>Address Information</FormGroupTitle>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Address 1</FormLabel>
                                    <Controller
                                        name={"address"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Address'}
                                                error={errors.address}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>Address 2</FormLabel>
                                    <Controller
                                        name={"address2"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Address 2'}
                                                error={errors.address2}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>City</FormLabel>
                                    <Controller
                                        name={"city"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'City'}
                                                error={errors.city}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                                <FormGroupCol>
                                    <FormLabel>State</FormLabel>
                                    <Controller
                                        name={"state_id"}
                                        control={control}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormSelect
                                                {...field}
                                                name={'state_id'}
                                                placeholder={'State'}
                                                value={field.value}
                                                options={statesOptions}
                                                error={errors.state_id}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                            <FormGroupRow>
                                <FormGroupCol>
                                    <FormLabel>Zip Code</FormLabel>
                                    <Controller
                                        name={"zip"}
                                        control={control}
                                        rules={{
                                            pattern: zipPattern,
                                        }}
                                        render={({ field: { ref, ...field } }) => (
                                            <FormInput
                                                inputSize={'xm'}
                                                placeholder={'Zip Code'}
                                                maxLength={5}
                                                error={errors.zip}
                                                {...field}
                                            />
                                        )}
                                    />
                                </FormGroupCol>
                            </FormGroupRow>
                        </FormGroup>

                        {/*/////////////////////////////*/}

                        <FormGroup>
                            <FormGroupRow>
                                <FormButton
                                    title={'Save'}
                                    onClick={handleSubmit((data) => handleOnSubmit(data))}
                                    width={164}
                                />
                            </FormGroupRow>
                        </FormGroup>

                    </Form>

                    <RecentMessages
                        deceasedId={customer?.id || 0}
                        messages={customer?.last_messages || []}
                        opt_out_at={customer?.opt_out_at }
                    />

                </PageGroup>
            </PageContent>
        </PageContentContainer>
    );
};

export default Customer;
