export const defaultTheme = {
    pageWidth: "1440px",
    backgroundColor: "#F1F5FA",
    whiteColor: "#FFFFFF",
    greyColor: "#C4C4C4",
    greenColorHovered: "#21636D",
    lightGreyColor: "#858585",
    greyTextColor: "#677477",
    blackTextColor: "#262626",
    greenColor: "#7CB5BE",
    fontFamily: '"Roboto", sans-serif',
    buttonGreen: "#7CB5BE",
    buttonRed: "#FF6552",
    yellowColor: "#DFDD2C",
    buttonYellow: "#DFDD2C",
    massageOutcomeColor: "#EDF6F8",
    massageOutcomeErrorColor: "#FFDEDA",
    massageIncomeColor: "#FBFBE1",
    errorColor: "#f30000",
    dialogNewMessagesBackgroundColor: "#FFDEDA",
    dialogNewMessagesTextColor: "#FF6552",
    dialogNewMessagesTextColorBold: "#ff1900",
    disabledBackgroundColor: "#faf8f8",
    disabledTextColor: "#c0c0c0",
};