import React, {FC} from 'react';
import {Autocomplete, TextField} from "@mui/material";

export interface Props {
    onChange?: any;
    onSearch?: any;
    items: Array<any>;
    isLoading?: boolean;
    placeholder?: string;
    width?: number;
    inputValue?: string;
    className?: string;
}

const AutocompleteMUI: FC<Props> = ({className, inputValue = '', onChange, onSearch, items, isLoading, placeholder, width = 234, ...props}) => {

    return (
        <div className={className}>
            <Autocomplete
                getOptionLabel={(option) => option.name || inputValue}
                onChange={onChange}
                onInputChange={onSearch}
                loading={isLoading}
                options={items}
                freeSolo
                clearOnBlur={false}
                inputValue={inputValue}
                // disableClearable={false}
                style={{
                    width: `${width}px`,
                }}
                ListboxProps={{
                    sx: {
                        fontSize: 14,
                        color: "#677477",
                    },
                }}
                sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                        fontSize: 14,
                        color: "#677477",
                        '&::placeholder': {
                            fontStyle: "italic",
                        },
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            style: { height: "36px"}
                        }}
                    />
                )}
                {...props}
            ></Autocomplete>
        </div>

    );
};

export default AutocompleteMUI;
