import React, {FC} from 'react';
import {StyledButton, Buttons, Counter, Wrapper, Arrow, SmallButton} from "./styled";
import ArrowLeft from '../../../assets/images/icons/arrow-left.svg';
import ArrowRight from '../../../assets/images/icons/arrow-right.svg';

export interface PaginationProps {
    data?: any[];
    rowCount: number;
    lastPage: number;
    currentPage: number;
    rowsPerPage: number;
    from: number;
    to: number;
    onChangePage: any;
    subPagination?: boolean;
    infinityPagination?: boolean;
}

function Pagination(props: PaginationProps) {
    const {
        rowCount,
        currentPage,
        rowsPerPage,
        onChangePage,
        infinityPagination,
        to,
        data,
    } = props;
    const page = currentPage - 1;

    const getNumberOfPages = (rowCount: number, rowsPerPage: number) => {
        if (data && data.length < rowsPerPage) return 1;
        return Math.ceil(rowCount / rowsPerPage);
    };

    const numberOfPages = getNumberOfPages(rowCount, rowsPerPage);

    const handleFirstPageButtonClick = () => {
        onChangePage(1);
    };
    const handleLastPageButtonClick = () => {
        onChangePage(numberOfPages);
    };
    const handleBackButtonClick = () => {
        onChangePage(page);
    };
    const handleButtonClick = (newpage: number) => {
        onChangePage(newpage);
    };
    const handleNextButtonClick = () => {
        onChangePage(page + 2);
    };
    return (
        <Wrapper>
            <Counter>
                Displaying {(currentPage-1)*rowsPerPage+1} to {to} of {rowCount} entries
            </Counter>
            <Buttons>
                {!infinityPagination && (
                    <StyledButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}>
                        FIRST
                    </StyledButton>
                )}
                <SmallButton
                    disabled={page === 0}
                    onClick={handleBackButtonClick}>
                    <Arrow src={ArrowLeft}/>
                </SmallButton>
                {currentPage === numberOfPages && currentPage - 2 > 0 && (
                    <SmallButton
                        onClick={() => handleButtonClick(currentPage - 2)}>
                        {currentPage - 2}
                    </SmallButton>
                )}
                {currentPage > 1 && (
                    <SmallButton
                        onClick={() => handleButtonClick(currentPage - 1)}>
                        {currentPage - 1}
                    </SmallButton>
                )}
                <SmallButton $active={true}>
                    {currentPage}
                </SmallButton>
                {currentPage < numberOfPages && (
                    <SmallButton
                        onClick={() => handleButtonClick(currentPage + 1)}>
                        {currentPage + 1}
                    </SmallButton>
                )}
                {currentPage === 1 && currentPage + 2 <= numberOfPages && (
                    <SmallButton
                        onClick={() => handleButtonClick(currentPage + 2)}>
                        {currentPage + 2}
                    </SmallButton>
                )}
                <SmallButton
                    disabled={
                        (!infinityPagination ? page >= numberOfPages - 1 : false) ||
                        numberOfPages < 2
                    }
                    onClick={handleNextButtonClick}>
                    <Arrow src={ArrowRight}/>
                </SmallButton>
                {!infinityPagination && (
                    <StyledButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= numberOfPages - 1}>
                        LAST
                    </StyledButton>
                )}
            </Buttons>
        </Wrapper>
    );
}

export default Pagination;
