import React, {CSSProperties, FC} from 'react';
import {SpinnerStyled} from "./styled";
import ScaleLoader from "react-spinners/ScaleLoader";

interface ISpinner {
    isLoading: boolean;
}

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const Spinner: FC<ISpinner> = ({isLoading}) => {
    return (
        <SpinnerStyled>
            <ScaleLoader
                height={53}
                width={6}
                radius={3}
                margin={3}
                color={'#7CB5BE'}
                loading={isLoading}
                cssOverride={override}
                aria-label="Loading Spinner"
            />
        </SpinnerStyled>
    );
};

export default Spinner;
