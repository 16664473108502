import React, {FC} from 'react';

const NotFound: FC = () => {
    return (
        <div>
            Page Not Found
        </div>
    );
};

export default NotFound;
