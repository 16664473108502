import React, {FC} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {CampaignMessage} from "../../../../types";
import {Button, Radio} from "../../../controls";
import timezonesList from "../../../../constants/timezones";
import dragDrop from "../../../../assets/images/icons/drag_drop.svg"
import {FormInput} from "../styled";
import {
    CheckboxStyled,
    DatepickerMUIStyled,
    DragDropImg,
    DragHandle,
    FirstColumn,
    MessageLabel,
    MessageLabelCadence,
    MessageTitle,
    MessageWrapper,
    Radios,
    SecondColumn,
    SingleSelectStyled,
    TextareaStyled,
    TimepickerMUIStyled,
    TimeSettings,
} from "./styledMessage";

type Props = {
    item: CampaignMessage;
    idx: number;
    deleteMessage: (idx: number) => void;
    addNewMessage: () => void;
    register: any;
    total: number;
    dragHandleProps?: any;
    provided?: any;
    snapshot?: any;
    isDisabled?: boolean;
};

const weeksOptions = [
    {value: '1', label: '1 week'},
    {value: '2', label: '2 weeks'},
    {value: '3', label: '3 weeks'},
    {value: '4', label: '4 weeks'},
    {value: '5', label: '5 weeks'},
    {value: '6', label: '6 weeks'},
    {value: '7', label: '7 weeks'},
    {value: '8', label: '8 weeks'},
    {value: '9', label: '9 weeks'},
    {value: '10', label: '10 weeks'},
    {value: '11', label: '11 weeks'},
    {value: '12', label: '12 weeks'},
]

const Message: FC<Props> = ({item, idx, total, deleteMessage, addNewMessage, dragHandleProps, provided, snapshot, isDisabled = false, ...props}) => {
    const { control, watch, formState: {errors}} = useFormContext();

    const timezones = timezonesList.map(item => {
        return {value: item.abbr, label: `${item.title}`}
    })

    const position = watch(`messages.${idx}.position`);

    const cadence = useWatch({
        control,
        name: `messages.${idx}.cadence`,
        defaultValue: 'date'
    });

    const getError = (name: string) => {
        if (errors.messages && Array.isArray(errors.messages) && errors.messages[idx] && errors.messages[idx][name]) {
            return errors.messages[idx][name];
        } else return null;
    }

    return (
        <MessageWrapper provided={provided} snapshot={snapshot} >
            <FirstColumn>
                <MessageTitle>Message #{position}</MessageTitle>
                <MessageLabel>Message Type</MessageLabel>
                <Controller
                    name={`messages.${idx}.title`}
                    control={control}
                    defaultValue={''}
                    rules={{required: true}}
                    render={({field: {ref, ...field}}) => (
                        <FormInput
                            inputSize={'xm'}
                            width={210}
                            placeholder={'Name'}
                            disabled={isDisabled}
                            error={getError('title')}
                            {...field}
                        />
                    )}
                />
                <Controller
                    name={`messages.${idx}.is_active`}
                    control={control}
                    defaultValue={true}
                    render={({field: {ref, ...field}}) => (
                        <CheckboxStyled
                            label={'In Use'}
                            checked={field.value}
                            disabled={isDisabled}
                            {...field}
                        />
                    )}
                />
                {total>1 && (
                    <Button
                        title={'DELETE MESSAGE'}
                        color={'red'}
                        width={150}
                        disabled={isDisabled}
                        onClick={() => {
                            deleteMessage(idx)
                        }}
                    />
                )}
            </FirstColumn>
            <SecondColumn>
                {!isDisabled ? (
                    <DragHandle>
                        <DragDropImg
                            width={25}
                            src={dragDrop}
                            alt={''}
                            {...dragHandleProps}
                        />
                    </DragHandle>
                ) : (
                    <div style={{height: '18px'}}/>
                )}
                <MessageLabel>Message Auto-Text</MessageLabel>
                <Controller
                    name={`messages.${idx}.text`}
                    control={control}
                    defaultValue={''}
                    rules={{required: "This field is required"}}
                    render={({field: {ref, onChange, ...field}}) => (
                        <TextareaStyled
                            onChange={onChange}
                            autoHeight={true}
                            disabled={isDisabled}
                            error={getError('text')}
                            {...field}
                        />
                    )}
                />
                <MessageLabelCadence>Send Cadence</MessageLabelCadence>
                <Controller
                    name={`messages.${idx}.cadence`}
                    control={control}
                    defaultValue={'date'}
                    render={({field: {ref, ...field}}) => (
                        <Radios
                            row={true}
                            {...field}
                        >
                            <Radio
                                label={'By Date'}
                                value={'date'}
                                disabled={isDisabled}
                            />
                            <Radio
                                label={'By Duration'}
                                value={'duration'}
                                disabled={isDisabled}
                            />
                            <Radio
                                label={'By DOB'}
                                value={'dob'}
                                disabled={isDisabled}
                            />
                            <Radio
                                label={'By DOD'}
                                value={'dod'}
                                disabled={isDisabled}
                            />
                        </Radios>
                    )}
                />
                <TimeSettings>
                    {cadence==='duration' && (
                        <Controller
                            name={`messages.${idx}.after`}
                            control={control}
                            defaultValue={'1'}
                            render={({field: {ref, ...field}}) => (
                                <SingleSelectStyled
                                    {...field}
                                    options={weeksOptions}
                                    value={field.value}
                                    disabled={isDisabled}
                                    placeholder={'Select One'}
                                />
                            )}
                        />
                    )}
                    {cadence==='date' && (
                        <Controller
                            name={`messages.${idx}.date`}
                            control={control}
                            defaultValue={''}
                            render={({field: {ref, onChange, ...field}}) => (
                                <DatepickerMUIStyled
                                    onChange={onChange}
                                    disabled={isDisabled}
                                    {...field}
                                />
                            )}
                        />
                    )}
                    <Controller
                        name={`messages.${idx}.time`}
                        control={control}
                        defaultValue={''}
                        render={({field: {ref, onChange, ...field}}) => (
                            <TimepickerMUIStyled
                                onChange={onChange}
                                disabled={isDisabled}
                                minutesStep={5}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name={`messages.${idx}.timezone`}
                        control={control}
                        defaultValue={'PST8PDT'}
                        render={({field: {ref, ...field}}) => (
                            <SingleSelectStyled
                                {...field}
                                options={timezones}
                                value={field.value}
                                disabled={isDisabled}
                                placeholder={'Select One'}
                            />
                        )}
                    />
                </TimeSettings>
            </SecondColumn>
        </MessageWrapper>
    );
};

export default Message;
