import styled from "styled-components";
import DatePicker from "react-datepicker";

interface ContainerProps {
    inputSize?: string;
}

const getInputSize = () => (props: ContainerProps) => {
    switch (props.inputSize) {
        case 'sm' :
            return {
                height: '36px',
                //maxWidth: '322px',
                width: '234px',
            }
        case 'xm' :
            return {
                height: '40px',
                width: '200px',
            }
        case 'md' :
            return {
                height: '40px',
                width: '346px',
            }
        case 'lg' :
            return {
                height: '48px',
                width: '320px',
            }
    }
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  position: relative;
  ${getInputSize()};
`;

export const StyledDatePicker = styled(DatePicker)`
  height: 40px;
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.greyTextColor};
  box-sizing: border-box;
  padding: 14px;
  width: 100%;
`;