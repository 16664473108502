import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1232px;
  background-color: #FFFFFF;
  box-shadow: 0 0 20px #D9E0E9;
  border-radius: 8px;
`;
