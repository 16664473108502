import React, {FC, useEffect, useRef, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import moment from "moment";
import ScaleLoader from "react-spinners/ScaleLoader";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {DialogProps, DialogSearchProps} from "../../../types";
import {PageContent, PageContentContainer, PageHeader, SearchPanel, Spinner} from "../../common";
import solidPhone from "../../../assets/images/icons/solid-phone.svg";
import Dialog from "./parts/Dialog";
import Message from "./parts/Message";
import {SmsMessage} from "../../controls";
import {fetchUser} from "../../../store/auth/authSlice";
import {
    fetchDialogMessages,
    fetchDialogs,
    fetchDialogSearch,
    fetchSendMessage,
    fetchSetInboundMessages,
    fetchUpdateDialogMessages,
    resetMessages,
    resetSearchResults
} from "../../../store/sms/smsSlice";
import {
    Buttons,
    Dialogs,
    DialogSearch,
    DialogsPanel,
    Loading,
    Messages,
    MessagesFooter,
    MessagesHeader,
    MessagesHeaderCol,
    MessagesHeaderDate,
    MessagesHeaderName,
    MessagesList,
    MessageText,
    NoDialogs,
    NoMessages,
    NotSelected,
    OptOut,
    OptOutBlock,
    ReplyTo,
    StyledAutocomplete,
    StyledButton,
    Wrapper,
} from "./styled";

const SmsPanel: FC = () => {
    const {dialogId: dialogIdParam} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const messagesRef = useRef<null | HTMLDivElement>(null);
    const [selectedDeceasedId, setSelectedDeceasedId] = useState<number | null>(null);
    const [selectedDialog, setSelectedDialog] = useState<any>(null);
    const [searchString, setSearchString] = useState<string>('');
    const [customerFromDialogIdParam, setCustomerFromDialogIdParam] = useState<string>('');
    const [messageText, setMessageText] = useState<string>('');
    const [dialogsList, setDialogsList] = useState<Array<DialogSearchProps|DialogProps>>([]);
    const {
        searchResults,
        dialogs,
        messages,
        isDialogsLoading,
        isMessagesLoading,
        isLoading
    } = useAppSelector(state => state.sms);

    useEffect(() => {
        if (dialogIdParam) {
            if (Number(dialogIdParam)) {
                dispatch(fetchDialogs({deceased_id: dialogIdParam})).then(resp => {
                    if (resp?.type === 'sms/dialogs/fulfilled') {
                        if (!resp.payload.data.resource.data.length) {
                            dispatch(fetchDialogSearch({deceased_id: dialogIdParam})).then(resp => {
                                if (resp?.type === 'sms/dialog-search/fulfilled') {
                                    if (!resp.payload.data.resource.length) {
                                        navigate('/sms-panel');
                                        setSelectedDeceasedId(null);
                                        dispatch(fetchDialogs({}));
                                    }
                                }
                            })
                        }
                    }
                });
                setSelectedDeceasedId(Number(dialogIdParam));
            } else {
                navigate('/sms-panel');
                setSelectedDeceasedId(null);
                dispatch(fetchDialogs({}));
            }
        } else {
            dispatch(fetchDialogs({}));
        }
    }, []);

    useEffect(() => {
        setMessageText('');
        if (selectedDeceasedId && selectedDialog?.dialog_id) {
            dispatch(fetchDialogMessages(selectedDialog.dialog_id)).then(resp => {
                handleScrollDown();
            });
        } else {
            dispatch(resetMessages());
        }
    }, [selectedDeceasedId]);

    useEffect(() => {
        setDialogsList(JSON.parse(JSON.stringify(dialogs)));
    }, [dialogs]);

    useEffect(() => {
        if (searchResults && (searchString.length || searchResults.length)) {
            setDialogsList(JSON.parse(JSON.stringify(searchResults)));
        }
    }, [searchResults]);

    useEffect(() => {
        if (dialogsList && dialogsList.length && selectedDeceasedId) {
            handleSelectDialog(Number(selectedDeceasedId));
        }
    }, [dialogsList]);

    useEffect(() => {
        if (selectedDialog && selectedDialog.dialog_id && selectedDeceasedId) {
            if (dialogIdParam){
                const name = `${selectedDialog.customer_first_name} ${selectedDialog.customer_first_name} - ${selectedDialog.deceased_first_name} ${selectedDialog.deceased_last_name}`;
                setCustomerFromDialogIdParam(name);
                setSearchString(name);
            }
            dispatch(fetchDialogMessages(selectedDialog.dialog_id)).then(resp => {
                handleScrollDown();
            });
        }
    }, [selectedDialog]);

    useEffect(() => {
        if (searchString === '' && !customerFromDialogIdParam && !dialogIdParam) handleAutocompleteReset();
        if (customerFromDialogIdParam && (searchString !== customerFromDialogIdParam)) {
            navigate('/sms-panel');
            setCustomerFromDialogIdParam('');
            if (searchString === '') handleAutocompleteReset();
        }
    }, [searchString]);

    useEffect(() => {
        if (messages && messages[0] && messages[0].dialog && selectedDialog && selectedDialog.unread_count > 0) {
            updateDialogsList(messages[0].dialog.deceased_id,{unread_count: 0});
        }

        const interval = setInterval(() => {
            if (selectedDeceasedId && selectedDialog?.dialog_id) {
                dispatch(fetchUpdateDialogMessages(selectedDialog.dialog_id));
            }
        }, 15000);

        return () => clearInterval(interval);
    }, [messages]);

    const handleSelectDialog = (deceasedId: number) => {
        if (deceasedId && dialogsList) {
            const dialog = [...dialogsList].find((item: any) => item.deceased_id === deceasedId);
            if (dialog) {
                setSelectedDeceasedId(dialog.deceased_id || 0);
                setSelectedDialog(dialog);
            }
            dispatch(fetchUser());
        }
    }

    const getSelectedDialog = (deceasedId: number): boolean => {
        return !!(selectedDialog && selectedDialog.deceased_id === deceasedId);
    }

    const handleOnMessageChange = (e: any) => {
        setMessageText(e.target.value);
    }

    const handleOnMessageSend = () => {
        if (selectedDialog && selectedDialog.deceased_id && messageText.length) {
            dispatch(fetchSendMessage({
                data: {
                    deceased_id: selectedDialog?.deceased_id,
                    message: messageText,
                }
            })).then(resp => {
                if (resp?.type === 'sms/send-message/fulfilled') {
                    setMessageText('');
                    handleScrollDown();
                    if (!selectedDialog.dialog_id) {
                        const {dialog_id, created_at, dialog: {deceased_id}} = resp?.payload?.data?.resource || null;
                        if (deceased_id && deceased_id === selectedDialog.deceased_id) {
                            setSelectedDialog({...selectedDialog, last_msg_at: created_at, dialog_id});
                            const dialogIdx = dialogsList.findIndex(item => item.deceased_id === deceased_id);
                            dialogsList[dialogIdx] = {...dialogsList[dialogIdx], dialog_id: dialog_id, last_msg_at: created_at};
                        }
                    }
                }
            });
        }
    }

    const handleScrollDown = () => {
        if (messagesRef.current) {
            messagesRef.current?.scrollTo(9999, 9999);
        }
    }

    const handleAutocompleteLoad = (query: string) => {
        setSearchString(query);
        if (query.length > 1) {
            dispatch(fetchDialogSearch({query}));
        }
    }

    const handleAutocompleteReset = () => {
        setSearchString('');
        setSelectedDialog(null);
        setSelectedDeceasedId(null);
        navigate('/sms-panel');
        dispatch(resetSearchResults());
        dispatch(fetchDialogs({}));
    }

    const handleOnSetInboundMessages = () => {
        if (selectedDialog) {
            dispatch(fetchSetInboundMessages({deceased_id: selectedDialog.deceased_id, action: selectedDialog.opt_out_at ? 'enable' : 'disable'})).then(resp => {
                if (resp?.type === 'sms/set-inbound-messages/fulfilled') {
                    setSelectedDialog({...selectedDialog, opt_out_at: resp.payload.data.resource.opt_out_at});
                    updateDialogsList(resp.payload.data.resource.deceased_id, {opt_out_at: resp.payload.data.resource.opt_out_at});
                }
            });
        }
    }

    const updateDialogsList = (deceasedId: number, data: object) => {
        const dialogsListArr = dialogsList;
        const dialog = dialogsList.find((item: any) => item.deceased_id === deceasedId);
        if (dialog) {
            const idx = dialogsListArr.indexOf(dialog);
            dialogsList[idx] = {...dialogsList[idx], ...data};
            setDialogsList([...dialogsListArr]);
        }
    }

    return (
        <PageContentContainer>
            <Spinner isLoading={isLoading}/>
            <PageHeader>
                <SearchPanel
                    title={'SMS Panel'}
                >
                    {/*<Label>Search:</Label>*/}
                    {/*<Input*/}
                    {/*    name={'search'}*/}
                    {/*    type={'search'}*/}
                    {/*    inputSize={'sm'}*/}
                    {/*    maxLength={30}*/}
                    {/*/>*/}
                    {/*<Button*/}
                    {/*    color={'yellow'}*/}
                    {/*    width={44}*/}
                    {/*    title={*/}
                    {/*        <img src={searchIcon} alt={''}/>*/}
                    {/*    }*/}
                    {/*/>*/}
                </SearchPanel>
            </PageHeader>
            <PageContent>
                <Wrapper>
                    <DialogsPanel>
                        <DialogSearch>
                            <StyledAutocomplete
                                items={[]}
                                placeholder={'Search'}
                                showNoResults={false}
                                inputSearchString={searchString}
                                onSearch={(q: string)=>handleAutocompleteLoad(q)}
                                onClear={handleAutocompleteReset}
                                inputDebounce={500}
                                maxLength={40}
                            />
                        </DialogSearch>
                        <Dialogs>
                            {!isDialogsLoading ?
                                (dialogsList.length ? dialogsList.map((item: any, key: number) => (
                                    <Dialog
                                        key={key}
                                        onClick={() => handleSelectDialog(item.deceased_id)}
                                        customer={`${item.customer_first_name} ${item.customer_last_name}`}
                                        deceased={`${item.deceased_first_name} ${item.deceased_last_name}`}
                                        company={item.company}
                                        location={item.company_location}
                                        last_message_at={item.last_msg_at}
                                        isSelected={getSelectedDialog(item.deceased_id)}
                                        unreadCount={item.unread_count}
                                    />
                                )) : (
                                    <NoDialogs>
                                        {searchString.length ? 'Nothing found' : 'No dialogs yet'}
                                    </NoDialogs>
                                )) : (
                                    <Loading>
                                        <ScaleLoader
                                            color={'#7CB5BE'}
                                        />
                                    </Loading>
                                )
                            }
                        </Dialogs>
                    </DialogsPanel>
                    {(selectedDeceasedId && selectedDialog) ? (
                        <Messages>
                            <MessagesHeader>
                                <MessagesHeaderCol>
                                    <MessagesHeaderName>
                                        {`${selectedDialog?.customer_first_name} ${selectedDialog?.customer_last_name} - ${selectedDialog?.deceased_first_name} ${selectedDialog?.deceased_last_name}`}
                                    </MessagesHeaderName>
                                    <MessagesHeaderName>
                                    <span>
                                        {`${selectedDialog?.company} - ${selectedDialog?.company_location}`}
                                    </span>
                                    </MessagesHeaderName>
                                    <MessagesHeaderDate>
                                        {selectedDialog?.last_msg_at ? `Last message: ${moment.utc(selectedDialog?.last_msg_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:mm A')}` :
                                            <br/>}
                                    </MessagesHeaderDate>
                                </MessagesHeaderCol>
                                <MessagesHeaderCol>
                                    <StyledButton
                                        onClick={handleOnSetInboundMessages}
                                        title={selectedDialog.opt_out_at ? 'ENABLE MESSAGES' : 'OPT OUT OF MESSAGES'}
                                        color={selectedDialog.opt_out_at ? 'transparent' : 'yellow'}
                                        disabled={isLoading || isMessagesLoading || !selectedDeceasedId || (selectedDialog.opt_out_at && !!selectedDialog.is_customer_opt_out)}
                                        width={152}
                                    />
                                </MessagesHeaderCol>
                            </MessagesHeader>
                            {!isMessagesLoading ? (
                                <MessagesList ref={messagesRef}>
                                    {messages && messages.length ? messages.map((item: any, key: number) => (
                                        <Message
                                            key={key}
                                            onClick={() => {}}
                                            type={item.direction}
                                            owner={item.owner_role}
                                            message={item.message}
                                            messageAt={item.created_at}
                                            sendStatus={item.send_status}
                                        />
                                    )) : (
                                        <NoMessages>
                                            No messages yet
                                        </NoMessages>
                                    )}
                                </MessagesList>
                            ) : (
                                <MessagesList ref={messagesRef}>
                                <Loading>
                                    <ScaleLoader
                                        color={'#7CB5BE'}
                                    />
                                </Loading>
                                </MessagesList>
                            )}
                            <MessagesFooter>
                                {selectedDialog.opt_out_at && (
                                    <OptOutBlock>
                                        <OptOut>Opt Out on {moment(selectedDialog?.opt_out_at).format('MM/DD/YYYY h:mm A')}</OptOut>
                                    </OptOutBlock>
                                )}
                                <ReplyTo>
                                    Reply to:
                                    <img src={solidPhone} alt={''}/>
                                    <span>
                                        {selectedDialog?.customer_phone_number}
                                    </span>
                                </ReplyTo>
                                <MessageText>
                                    <SmsMessage
                                        name={'message'}
                                        onChange={handleOnMessageChange}
                                        value={messageText}
                                        disabled={!selectedDeceasedId || !!selectedDialog.opt_out_at}
                                        placeholder={'Type a Message'}
                                    />
                                </MessageText>
                                <Buttons>
                                    <StyledButton
                                        title={'SEND'}
                                        width={84}
                                        onClick={handleOnMessageSend}
                                        disabled={(!messageText.length || !selectedDeceasedId || !!selectedDialog.opt_out_at)}
                                    />
                                    {/*<StyledButton*/}
                                    {/*    title={'SEND AND CLOSE ESCALATION'}*/}
                                    {/*    color={'transparent'}*/}
                                    {/*    width={212}*/}
                                    {/*/>*/}
                                    {/*<StyledButton*/}
                                    {/*    title={'CLOSE ESCALATION'}*/}
                                    {/*    color={'yellow'}*/}
                                    {/*    width={152}*/}
                                    {/*/>*/}
                                </Buttons>
                            </MessagesFooter>
                        </Messages>
                    ) : (
                        <NotSelected>
                            Pick a contact from the left menu and start a conversation
                        </NotSelected>
                    )}
                </Wrapper>
            </PageContent>
        </PageContentContainer>
    );
};

export default SmsPanel;
