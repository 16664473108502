import React, {FC, InputHTMLAttributes} from 'react';
import {RadioStyled} from './styled';
import {CheckboxProps} from "@mui/material/Checkbox/Checkbox";
import {FormControlLabel} from "@mui/material";

interface Props extends CheckboxProps {
    name?: string;
    label?: string;
}

const Radio: FC<Props> = ({label = '', ...props}) => {
    return (
        <FormControlLabel
            control={
                <RadioStyled
                    {...props}
                />
            }
            label={label}
        />
    );
};

export default Radio;
