import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 370px;
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  background: ${(props) => props.theme.backgroundColor};
  padding: 32px 104px 40px 104px;

  @media screen and (max-width: 1366px){
    padding: 10px 10px 10px 10px;
  }
`;
