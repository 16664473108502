import styled from "styled-components";

export const LabelStyled = styled.label`
  display: flex;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1110px){
    display: none;
  }
`;
