import styled, {ThemeProps} from "styled-components";

interface ContainerProps {
    inputSize?: string;
    type?: string;
    width: number;
    height: number;
    error?: any;
}

const getInputSize = () => (props: ContainerProps & ThemeProps<any>) => {
    switch (props.inputSize) {
        case 'sm' :
            return {
                height: '36px',
                //maxWidth: '322px',
                width: '234px',
            }
        case 'xm' :
            return {
                height: '40px',
                width: '200px',
            }
        case 'md' :
            return {
                height: '40px',
                width: '346px',
            }
        case 'lg' :
            return {
                height: '48px',
                width: '320px',
            }
    }
}

const getWidth = () => (props: ContainerProps) => {
    if (props.width) {
        return {
            width: `${props.width}px`
        }
    }
}

const getHeight = () => (props: ContainerProps) => {
    if (props.height) {
        return {
            height: `${props.height}px`
        }
    }
}

const isError = () => (props: any) => {
    if (props.error) {
        return {
            borderColor: 'red'
        }
    }
}

export const Wrapper = styled.div`
    display: block;
`;

export const Container = styled.div<any>`
  display: flex;
  position: relative;
  //width: 320px;
  ${getInputSize()};
  ${getWidth()};
  ${getHeight()};
`;

export const EyeImg = styled.img`
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
`;

export const StyledInput = styled.input.attrs((props) => ({
    type: `${props.type}`
}))`
  margin: 0;
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 50px;
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.greyTextColor};
  ${isError()};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #a4b1b4;
  }

  &:disabled {
    background-color: ${(props) => props.theme.disabledBackgroundColor};
    color: ${(props) => props.theme.disabledTextColor};
  }
`;

export const StyledPasswordInput = styled(StyledInput)`
  padding-right: 45px;
`;

export const SearchDiv = styled.div`
  position: relative;
`;

export const StyledSearchInput = styled(StyledInput)`
  padding-left: 25px;
`;

export const SearchImg = styled.img`
  position: absolute;
  top: 11px;
  left: 6px;
  z-index: 1;
`;

export const Error = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.errorColor};
`;
