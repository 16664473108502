import React, {FC} from 'react';
import {StyledDatePicker, Container} from "./styled";
import {ReactDatePickerProps} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props extends ReactDatePickerProps {
    className?: string;
    inputSize?: string;
    error?: any;
}

const Datepicker: FC<Props> = ({value, className, inputSize = 'md', error = '', ...props}) => {
    return (
        <Container inputSize={inputSize}>
            <StyledDatePicker
                {...props}
            />
        </Container>

    );
};

export default Datepicker;
