import Router from './router/Router';
import {AlertModal} from "./components/common";

function App() {
    return (
        <>
            <Router/>
            <AlertModal
            />
        </>
    );
}

export default App;
