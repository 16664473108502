import React, {FC} from 'react';
import {StyledInputMask, Error} from "./styled";

interface Props {
    name: string;
    mask: string;
    error?: any;
}

const InputMask: FC<Props> = ({error = '', ...props}) => {
    return (
        <div>
            <StyledInputMask
                {...props}
            />
            {!!error && error.message && (
                <Error className={'errorMsg'}>
                    {`${error.message}`}
                </Error>
            )}
        </div>
    );
};

export default InputMask;
