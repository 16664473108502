import styled from "styled-components";

const isError = () => (props: any) => {
    if (props.error) {
        return {
            borderColor: 'red'
        }
    }
}

export const Wrapper = styled.div`
  position: relative;
`;

export const BackSide = styled.div`
  position: absolute;
  top: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: scroll;
  padding: 14px 14px 22px 14px;
  z-index: 2;
  color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-spacing: 2px;
  width: calc(100% - 29px);
  height: 100%;
`;

export const TextareaStyled = styled.textarea`
  position: relative;
  padding: 14px 14px 22px 14px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-spacing: 2px;
  color: ${(props) => props.theme.greyTextColor};
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  overflow-y: scroll;
  background-color: transparent;
  z-index: 3;
  height: 100%;
  
  ${isError()};

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #a4b1b4;
  }

  &:disabled {
    background-color: ${(props) => props.theme.disabledBackgroundColor};
    color: ${(props) => props.theme.disabledTextColor};
  }
`;
