import {createSlice, createAsyncThunk, configureStore} from '@reduxjs/toolkit';
import { format } from 'date-fns';
import api from '../../service/api';
import {ActivityProps, CustomerProps} from "../../types";

export const fetchSmsActivity = createAsyncThunk('sms/activity', async (params: any) => {
    const response = await api.get('sms/activity', { params });
    return response.data;
});

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CustomerState {
    items: Array<ActivityProps>;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: CustomerState = {
    items: [],
    listFilters: {
        order_by: 'created_at',
        order_dir: 'desc',
        page: 1,
        ipp: 25,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const smsActivitySlice = createSlice({
    name: 'smsActivity',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSmsActivity.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSmsActivity.fulfilled, (state: any, action: any) => {
                const {data, current_page, total, last_page, ipp, per_page, from, to} = action.payload.data.resource;
                state.items = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    ipp: ipp,
                    page: current_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                }
                state.isLoading = false;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchSmsActivity.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

export const { setFilterParams, resetFilters } = smsActivitySlice.actions;

export default smsActivitySlice.reducer;
