import styled from "styled-components";
import {Button, MultiSelect, SingleSelect} from "../../controls";
import Input from "../../controls/Input/Input";
import {Error} from "../../controls/Input/styled";

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.blackTextColor};
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin: 4px 0 18px 32px;
  justify-content: space-between;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 650px;
  width: 100%;
  user-select:none;
  
  @media screen and (max-width: 1150px) {
    min-width: 0;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
`;

export const CampaignName = styled.div`
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.greenColor};
`;

export const FormGroup = styled.div`

`;

export const FormGroupRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`;

export const FormGroupCol = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FormLabel = styled.label`
  width: 100px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
  white-space: nowrap;
  text-align: right;

  @media screen and (max-width: 880px) {
    text-align: left;
    margin-bottom: 5px;
  }
`;

export const FormInput = styled(Input)`
  ${Error} {
    position: absolute;
  }
`;

export const FormSingleSelect = styled(SingleSelect)`
  width: 250px;
  min-width: 250px;
`;

export const FormMultiSelect = styled(MultiSelect)`
  width: 250px;
  min-width: 250px;
`;

export const FormButtons = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 20px;
`;

export const FormButton = styled(Button)`
  margin-top: -30px;
`;

export const AddNewMessage = styled(Button)`

`;

export const DraggableWrapper = styled.div`
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Legend = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: rgba(38, 38, 38, 0.2);
  width: 330px;
  height: 100%;
  border-radius: 4px;
`;

export const LegendHeader = styled.h3`
  margin: 5px 0 12px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
`;

export const LegendItemsWrapper = styled.div`
  display: flex;
  padding: 0 10px 10px 10px;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  min-width: 332px;

  @media screen and (max-width: 700px) {
    min-width: 92px;
  }
  
`;

export const LegendItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 12px;
`;

export const LegendItem = styled.div`
  width: 72px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  padding: 3px 10px;
  background-color: rgba(243, 240, 240, 0.66);
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
  text-align: center;
  cursor: grab;

  &:hover {
    background-color: rgba(238, 236, 236, 0.91);
    font-weight: 500;
    border-color: rgba(38, 38, 38, 0.5);
  }
`;

export const MessagesWrapper = styled.div`
  z-index: 0;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #FFFFFF;
`;

export const Divider = styled.hr`
  margin: 0 0 20px 0;
  color: rgba(38, 38, 38, 0.15);
`;
