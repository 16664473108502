import React, {FC, useEffect} from 'react';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment, {Moment} from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import {defaultTheme} from "../../../themes/main";

interface Props {
    label?: string,
    value: any,
    onChange: any;
    disabled?: boolean;
}

const DatepickerMUI: FC<Props> = ({value, onChange, label = '', disabled = false, ...props}) => {
    const [val, setVal] = React.useState<Moment | null>(moment().tz('UTC').set({"hour": 12, "minute": 0}));

    useEffect(() => {
        if (value) setVal(value);
    }, [value]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={val}
                disabled={disabled}
                onChange={(newValue) => {
                    setVal(newValue);
                    onChange(newValue);
                }}
                slotProps={{ textField: { size: 'small' } }}
                sx={{
                    "& .MuiOutlinedInput-input": {
                        height: '24px',
                        fontSize: 14,
                        fontWeight: 400,
                        color: '#677477',
                        backgroundColor: "white",
                    },
                    ".MuiOutlinedInput-input.Mui-disabled": {
                        backgroundColor: `${defaultTheme.disabledBackgroundColor} !important`,
                    },
                }}
                {...props}
            />
        </LocalizationProvider>
    );
};

export default DatepickerMUI;
