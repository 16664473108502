import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
    
  // continue global styles here:
  //body {
  //  padding: 0;
  //  background-color: black;
  //}
`