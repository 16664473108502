import React, {FC, useEffect, useRef, useState} from 'react';
import moment from "moment/moment";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {fetchHistory, fetchUpload} from "../../../store/import/importSlice";
import {fileDownload} from "../../../store/general/generalSlice";
import {PageContent, PageContentContainer, PageHeader, Spinner} from "../../common";
import {Input} from "../../controls";
import {defaultTheme} from "../../../themes/main";
import {
    customDataTableStyles,
    LabelStyled,
    PageTitle,
    PreviousImport,
    Row,
    StyledDataTable,
    Table,
    TableFirstCol,
    TableRow, TableRowHeader,
    TableSecondCol,
    TemplateLink, UploadButton,
    Wrapper
} from "./styled";

const Import: FC = () => {

    const columns = [
        {
            name: 'Filename',
            alias: 'file_title',
            selector: (row: any) => row.file_title,
            sortable: false,
            cell: (row: any) =><div style={{cursor: "pointer"}} onClick={()=>{
                dispatch(fileDownload({file: row.full_file_path, title: row.file_title}));
            }
            }>{row.file_title}</div>,
            style: {
                color: defaultTheme.greenColor,
            }
        },
        {
            name: 'Date Uploaded',
            alias: 'created_at',
            selector: (row: any) => moment(row.created_at).format('MM/DD/YYYY hh:mm A'),
            sortable: false,
            width: '160px',
            wrap: true,
        },
        {
            name: 'Status',
            alias: 'status',
            selector: (row: any) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
            sortable: false,
            width: '90px',
        },
    ];

    const dispatch = useAppDispatch();
    const {history, isLoading} = useAppSelector(state => state.import);
    const hiddenFileInput = useRef<any>(null);
    const [fileTitle, setFileTitle] = useState('');

    useEffect(() => {
        dispatch(fetchHistory());
    }, []);

    const handleFileUpload = (e: any) => {
        const file = e.target.files[0] || null;
        if (file) {
            setFileTitle(file.name);
            dispatch(fetchUpload(file)).then(resp => {
                setFileTitle('');
            });
        }
        e.target.value = '';
    }

    const handleUploadClick = (event: any) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    return (
        <PageContentContainer>
            <Spinner isLoading={isLoading}/>
            <PageHeader>
                <PageTitle>Data Import</PageTitle>
            </PageHeader>
            <PageContent>
                <Wrapper>
                    <Row>
                        <LabelStyled>Import Decedent Data</LabelStyled>
                        <Input inputSize={'sm'} value={fileTitle || ''} readOnly/>
                        <UploadButton title={'BROWSE & UPLOAD'} width={152} color={'yellow'} onClick={handleUploadClick}/>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={handleFileUpload}
                            style={{display:'none'}}
                            accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
                        />
                        <TemplateLink href={'/example.xlsx'} target={'_blank'} rel={'noreferrer'}>Download Decedent Data Template</TemplateLink>
                    </Row>
                    <Row>
                        <PreviousImport>Previous Imports</PreviousImport>
                    </Row>
                    <Row>
                        <StyledDataTable
                            customStyles={customDataTableStyles}
                            data={history}
                            columns={columns}
                            pagination={false}
                        />

                        {/*<Table>*/}
                        {/*    <TableRowHeader>*/}
                        {/*        <TableFirstCol>*/}
                        {/*            Filename*/}
                        {/*        </TableFirstCol>*/}
                        {/*        <TableSecondCol>*/}
                        {/*            Date Uploaded*/}
                        {/*        </TableSecondCol>*/}
                        {/*        <TableSecondCol>*/}
                        {/*            Status*/}
                        {/*        </TableSecondCol>*/}
                        {/*    </TableRowHeader>*/}
                        {/*    {history && history.length && history.map((item: any, key: number)=>(*/}
                        {/*        <TableRow key={key}>*/}
                        {/*            <TableFirstCol>*/}
                        {/*                {item.file_title}*/}
                        {/*            </TableFirstCol>*/}
                        {/*            <TableSecondCol>*/}
                        {/*                {moment(item.created_at).format('MM/DD/YYYY hh:mm A')}*/}
                        {/*            </TableSecondCol>*/}
                        {/*            <TableSecondCol>*/}
                        {/*                {item.status}*/}
                        {/*            </TableSecondCol>*/}
                        {/*        </TableRow>*/}
                        {/*    ))}*/}
                        {/*</Table>*/}

                    </Row>
                </Wrapper>
            </PageContent>
        </PageContentContainer>
    );
};

export default Import;
