import React, {FC} from 'react';
import {Select, TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Placeholder} from "../MultiSelect/styled";
import {defaultTheme} from "../../../themes/main";

export interface Props {
    onChange?: any;
    onSearch?: any;
    options: Array<any>;
    placeholder?: string;
    width?: number;
    className?: string;
    value: string;
    label?: string;
    error?: any;
    disabled?: boolean;
}

const SingleSelect: FC<Props> = ({className, value, label= '', disabled= false, onChange, onSearch, options, width, error, ...props}) => {
    const getPlaceholder = (title: string | undefined) => {
        return <Placeholder>{title || ''}</Placeholder>;
    };

    return (
        <FormControl className={className}>
            <Select
                value={value}
                onChange={onChange}
                label={label}
                displayEmpty
                disabled={disabled}
                renderValue={(selected) => {
                    if (selected.length) {
                        return options.find(item=>item.value===selected)?.label;
                    } else {
                        return getPlaceholder(props.placeholder);
                    }
                }}
                style={{
                    width: `${width}px`,
                    height: '40px',
                    fontSize: '14px',
                    backgroundColor: "white",
                }}
                sx={{
                    color: '#677477',
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                        border: '1px solid',
                        borderColor: error ? 'red' : '#C4C4C4',
                    },
                    "&.Mui-disabled": {
                        backgroundColor: `${defaultTheme.disabledBackgroundColor} !important`,
                    },
                    "&>.MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: defaultTheme.disabledTextColor,
                    },
                }}
                {...props}
            >
                {options.map((option: any, k) => (
                    <MenuItem key={k} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SingleSelect;
