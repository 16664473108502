import styled from "styled-components";
import { GoTrashcan } from 'react-icons/go';

export const GoTrashcanStyled = styled(GoTrashcan)`
  width: 16px;
  height: 16px;
  color: red;
  cursor: pointer;
`;


