import React, {FC, useEffect, useState} from 'react';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import {SelectProps} from "@mui/material/Select/Select";
import {Placeholder} from "./styled";
import {defaultTheme} from "../../../themes/main";

interface Props extends SelectProps{
    value: Array<string>;
    options: {value: string, label: string}[];
    onChange: any;
    error?: any;
    width?: number;
    className?: string;
}

const MenuProps = {
    PaperProps: {
        // style: {
        //     height: 400,
        //     maxHeight: 400,
        //     width: 250
        // }
    }
};

const MultiSelect: FC<Props> = ({className, onChange, value, width,  options, error = '', ...props}) => {
    const [selected, setSelected] = useState<Array<any>>([]);
    const isAllSelected = options.length > 0 && selected.length === options.length;
    const handleChange = (event: any) => {
        let value = event.target.value;
        if (value[value.length - 1] === "all") {
            value = selected.length === options.length ? [] : options.map(item=>item.value);
        }
        setSelected(value);
        onChange(value);
    };

    const getPlaceholder = (title: string | undefined) => {
        return <Placeholder>{title || ''}</Placeholder>;
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);

    return (
        <FormControl className={className}>
            <Select
                multiple
                value={selected}
                onChange={handleChange}
                MenuProps={MenuProps}
                displayEmpty
                renderValue={(selected) => {
                    if (Array.isArray(selected) && selected.length >= 1) {
                        const firstItem = options.find(item=>item.value===selected[0]);
                        return (selected.length === 1 && firstItem) ? firstItem.label : `${selected.length} items selected`;
                    } else {
                        return getPlaceholder(props.placeholder);
                    }
                }}
                style={{
                    width: `${width}px`,
                    height: '40px',
                    fontSize: '14px'
                }}
                sx={{
                    color: '#677477',
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: '1px solid',
                        borderColor: error ? 'red' : '#C4C4C4'
                    },
                    "&.MuiInputBase-root.Mui-disabled": {
                        backgroundColor: defaultTheme.disabledBackgroundColor,
                    },
                    "&>.MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: defaultTheme.disabledTextColor,
                    },
                }}
                {...props}
            >
                <MenuItem
                    value="all"
                    sx={{
                       fontWeight: 900
                    }}
                >
                    <Checkbox
                        checked={isAllSelected}
                        indeterminate={selected.length > 0 && selected.length < options.length}
                    />
                    <ListItemText
                        primary={selected.length === options.length ? 'Unselect All' : 'Select All'}
                        sx={{
                            "& .MuiListItemText-primary": {
                                color: '#7CB5BE',
                                fontSize: '1rem',
                                fontWeight: 600,
                                fontStyle: 'italic',
                                textDecoration: 'underline',
                                textUnderlineOffset: '0.2rem'
                            }
                        }}
                    />
                </MenuItem>
                {options.map((option: any, k) => (
                    <MenuItem key={k} value={option.value}>
                        <Checkbox checked={selected.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

// Search - https://stackoverflow.com/questions/68225786/search-input-as-option-in-material-ui-select-component - https://codesandbox.io/s/react-mui-searchable-select-nm3vw?file=/src/App.js

export default MultiSelect;
