import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {DialogMessageProps, DialogProps, DialogSearchProps} from "../../types";

export const fetchDialogSearch = createAsyncThunk('sms/dialog-search', async (params: any) => {
    const response = await api.get(`sms/dialog-search`, {params});
    return response.data;
});

export const fetchDialogs = createAsyncThunk('sms/dialogs', async (params: any) => {
    const response = await api.get('sms/dialogs', { params });
    return response.data;
});

export const fetchDialogMessages = createAsyncThunk('sms/dialog-messages', async (id: number) => {
    const response = await api.get(`sms/dialog/${id}/messages`);
    return response.data;
});

export const fetchUpdateDialogMessages = createAsyncThunk('sms/update-dialog-messages', async (id: number) => {
    const response = await api.get(`sms/dialog/${id}/messages-update`);
    return response.data;
});

export const fetchSendMessage = createAsyncThunk('sms/send-message', async (params: any, thunkAPI) => {
    const {data, cb} = params;
    try {
        const response = await api.post(`sms/dialog/send-message`, data)
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchSetInboundMessages = createAsyncThunk('sms/set-inbound-messages', async (params: any, thunkAPI) => {
    const {deceased_id, action} = params;
    try {
        const response = await api.post(`sms/dialog/${deceased_id}/inbound-messages/${action}`)
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)


interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CustomerState {
    searchResults: Array<DialogSearchProps>;
    dialogs: Array<DialogProps>;
    messages: Array<DialogMessageProps>;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
    isSearchLoading: boolean;
    isDialogsLoading: boolean;
    isMessagesLoading: boolean;
    isSending: boolean;
}

const initialState: CustomerState = {
    searchResults: [],
    dialogs: [],
    messages: [],
    listFilters: {
        order_by: 'name',
        order_dir: 'asc',
        page: 1,
        ipp: 10,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
    isSearchLoading: false,
    isDialogsLoading: false,
    isMessagesLoading: false,
    isSending: false,
};

export const smsSlice = createSlice({
    name: 'sms',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetMessages: (state) => {
            state.messages = initialState.messages;
        },
        resetSearchResults: (state) => {
            state.searchResults = initialState.searchResults;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUpdateDialogMessages.fulfilled, (state: any, action: any) => {
                state.messages = action.payload.data.resource;
            })
            .addCase(fetchDialogSearch.pending, state => {
                state.isDialogsLoading = true;
            })
            .addCase(fetchDialogSearch.fulfilled, (state: any, action: any) => {
                state.searchResults = action.payload.data.resource;
                state.isDialogsLoading = false;
            })
            .addCase(fetchDialogSearch.rejected, state => {
                state.isDialogsLoading = false;
            })
            .addCase(fetchDialogs.pending, state => {
                state.isDialogsLoading = true;
            })
            .addCase(fetchDialogs.fulfilled, (state: any, action: any) => {
                const {data, current_page, total, last_page, ipp, per_page, from, to} = action.payload.data.resource;
                state.dialogs = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    ipp: ipp,
                    page: current_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                }
                state.isDialogsLoading = false;
            })
            .addCase(fetchDialogs.rejected, state => {
                state.isDialogsLoading = false;
            })
            .addCase(fetchDialogMessages.pending, state => {
                state.isMessagesLoading = true;
            })
            .addCase(fetchDialogMessages.fulfilled, (state: any, action: any) => {
                state.messages = action.payload.data.resource;
                state.isMessagesLoading = false;
            })
            .addCase(fetchDialogMessages.rejected, state => {
                state.isMessagesLoading = false;
            })
            .addCase(fetchSendMessage.pending, state => {
                state.isSending = true;
            })
            .addCase(fetchSendMessage.fulfilled, (state: any, action: any) => {
                const messages = state.messages;
                messages.push(action.payload.data.resource)
                state.messages = messages;
                state.isSending = false;
            })
            .addCase(fetchSendMessage.rejected, state => {
                state.isSending = false;
            })
            .addCase(fetchSetInboundMessages.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSetInboundMessages.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetInboundMessages.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

export const { setFilterParams, resetFilters, resetMessages, resetSearchResults } = smsSlice.actions;

export default smsSlice.reducer;
