import {createSlice, createAsyncThunk, Action} from '@reduxjs/toolkit';
import api from '../../service/api';

export const fetchSsoLogin = createAsyncThunk('auth/login-sso', async (params: any) => {
    const response = await api.post('auth/sso-login', params);
    return response.data;
});

export const fetchUser = createAsyncThunk('auth/user', async () => {
    const response = await api.get('auth/user');
    return response.data;
});

export const fetchLogout = createAsyncThunk('auth/logout', async () => {
    const response = await api.get('auth/logout');
    return response.data;
});

export const fetchForgotPassword = createAsyncThunk('auth/forgot_password', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('auth/password-recovery', params);
    return response.data;
});

export const fetchSetPassword = createAsyncThunk('auth/set_password', async (params: any, {
    rejectWithValue,
    dispatch
}) => {
    const response = await api.post('auth/password-restore', params);
    return response.data;
});

interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    user: any;
    role: string | null;
    isLoading: boolean;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    user: null,
    role: null,
    isLoading: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setTokens: (state, action: any) => {
            const {access_token, refresh_token} = action;
            if (access_token) {
                localStorage.setItem('access_token', access_token);
                state.accessToken = access_token;
            }
            if (refresh_token) {
                localStorage.setItem('refresh_token', refresh_token);
                state.refreshToken = refresh_token;
            }
        },
        resetTokens: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('role');
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSsoLogin.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSsoLogin.fulfilled, (state: any, action: any) => {
                const {credentials: {access_token, refresh_token}, role: {alias}} = action.payload.data.resource;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('role', alias);
                return {...state, isLoading: false, accessToken: access_token, refreshToken: refresh_token, role: alias};
            })
            .addCase(fetchSsoLogin.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUser.fulfilled, (state: any, action: any) => {
                const {resource} = action.payload.data
                state.user = resource;
                state.role = resource.role.alias || null;
                localStorage.setItem('role', resource.role.alias || null);
            })
            .addCase((fetchLogout.fulfilled || fetchLogout.rejected), state => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('role');
                return {...state, accessToken: null, refreshToken: null};
            })
            .addCase(fetchForgotPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchForgotPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchForgotPassword.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchSetPassword.fulfilled, state => {
                state.isLoading = false;
            })
            .addCase(fetchSetPassword.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state => {
                state.isLoading = false;
            });
    },
});

export const { setTokens, resetTokens } = authSlice.actions;
export default authSlice.reducer;
