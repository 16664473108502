export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in/:userId?',
  CUSTOMERS = '/customers',
  CUSTOMER = '/customer/:id',
  IMPORT = '/import',
  SMS_ACTIVITY = '/sms-activity',
  SMS_PANEL = '/sms-panel/:dialogId?',
  CAMPAIGNS = '/campaigns',
  CAMPAIGN_UPDATE = '/campaign/:id',
  CAMPAIGN_CREATE = '/campaign/create',
}
