import React, {FC} from 'react';
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {DialogMessageProps} from "../../../../types";
import {Button} from "../../../controls";
import {
    RecentMessageBlock,
    RecentMessageBlockButton,
    RecentMessageBlockDate,
    RecentMessageBlockText,
    RecentMessageNoMessages,
    RecentMessagesContent,
    RecentMessagesTitle,
    RecentMessagesWrapper,
    OptOut,
    OptOutBlock
} from "../styled";

type Props = {
    deceasedId: number;
    messages: Array<DialogMessageProps | null>;
    opt_out_at: string | null | undefined;
};

const RecentMessages: FC<Props> = ({messages, deceasedId, opt_out_at = null}) => {
    const navigate = useNavigate();
    return (
        <RecentMessagesWrapper>
            <RecentMessagesTitle>Recent Messages</RecentMessagesTitle>
            <RecentMessagesContent>
                {messages && messages.length ? (
                    <>
                        {[...messages].reverse().map((item: any, key: number) => (
                            <RecentMessageBlock key={key}>
                                <RecentMessageBlockText>
                                    {item.message}
                                </RecentMessageBlockText>
                                <RecentMessageBlockDate>
                                    <div>{moment.utc(item.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:mm A')}</div>
                                    <div>{item.direction==='outbound' ? item.send_status : ''}</div>
                                </RecentMessageBlockDate>
                            </RecentMessageBlock>
                        ))}
                        {opt_out_at && (
                            <OptOutBlock>
                                <OptOut>Opt Out on {moment.utc(opt_out_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:mm A')}</OptOut>
                            </OptOutBlock>
                        )}
                        <RecentMessageBlockButton>
                            <Button
                                onClick={()=>navigate(`/sms-panel/${deceasedId}`)}
                                title={'VIEW ALL'}
                                color={'transparent'}
                                width={164}
                            />
                        </RecentMessageBlockButton>
                    </>
                ) : (
                    <RecentMessageNoMessages>
                        No messages yet
                    </RecentMessageNoMessages>
                )}
            </RecentMessagesContent>
        </RecentMessagesWrapper>
    );
};

export default RecentMessages;
