import React, {FC, InputHTMLAttributes, useEffect, useRef} from 'react';
import {BackSide, TextareaStyled, Wrapper} from "./styled";

interface Props extends InputHTMLAttributes<HTMLElement> {
    name: string;
    onChange?: any;
    autoHeight?: boolean;
    error?: any;
}

const MessageEditor: FC<Props> = ({onChange, autoHeight = false, value, ...props}) => {
    const minRows = 3;
    const maxRows = 8;
    const textareaLineHeight = 20;
    const [rows, setRows] = React.useState(minRows);
    const [height, setHeight] = React.useState(0);
    const messageRef = useRef<HTMLTextAreaElement>(null);
    const backsideRef = useRef<HTMLDivElement>(null);
    const ua = window.navigator.userAgent.toLowerCase();
    const isIE = !!ua.match(/msie|trident\/7|edg/);

    useEffect(() => {
        window.addEventListener('resize', () => {
            handleResize()
        });
    }, []);

    useEffect(() => {
        handleInput();
        handleResize();
    }, [value]);

    useEffect(() => {
        handleInput();
        if (autoHeight) handleHeight();
        if (messageRef.current) {
            setHeight(messageRef.current.clientHeight - 36);
        }
    }, []);

    const handleHeight = () => {
        if (messageRef.current) {
            const previousRows = messageRef.current.rows;
            messageRef.current.rows = minRows;
            const currentRows = ~~((messageRef.current.scrollHeight - 35) / textareaLineHeight);

            if (currentRows === previousRows) {
                messageRef.current.rows = currentRows;
            }

            if (currentRows >= maxRows) {
                messageRef.current.rows = maxRows;
                messageRef.current.scrollTop = messageRef.current.scrollHeight;
            }

            setRows(currentRows < 10 ? currentRows : 10);
            handleResize();
        }
    };

    const applyHighlights = (text: string | undefined) => {
        if (text && !isIE) {
            return text.replace(/\n$/g, '\n\n')
                .replace(/\[([^\]]+)\]/g, '<mark>[$1]</mark>');
        } else return text ?? '';
    }

    const handleInput = () => {
        const text = messageRef?.current?.value;
        const current = backsideRef?.current
        if (current) {
            current.innerHTML = applyHighlights(text);
        }
    }

    const handleScroll = () => {
        if (messageRef.current) {
            const scrollTop = messageRef.current.scrollTop;
            if (backsideRef?.current) {
                backsideRef.current.scrollTop = scrollTop;
            }
            handleInput();
        }
    }

    const handleTextAreaClick = (e:any) => {
        const {target, target: {selectionStart, value}} = e;
        const start = value.lastIndexOf('[', selectionStart);
        const prev = value.lastIndexOf(']', selectionStart);
        const end = value.indexOf(']', selectionStart);
        const next = value.indexOf('[', selectionStart);
        if ((start >= 0 && start > prev) && (end >= 0 && (end < next || next === -1))) {
            target.setSelectionRange(start, end+1);
        }
    }

    const handleTextAreaChange = (e:any) => {
        const {nativeEvent: {inputType, data}, target, target: {selectionStart}} = e;
        if (inputType === 'insertFromDrop' && data) {
            target.setSelectionRange(selectionStart - data.length, selectionStart);
        }
    }

    const handleResize = () => {
        if (messageRef.current && backsideRef.current) {
            setHeight(messageRef.current.clientHeight - 36);
        }
    }

    return (
        <Wrapper className={props.className}>
            <TextareaStyled
                className={'textarea'}
                ref={messageRef}
                onResize={handleResize}
                onClick={handleTextAreaClick}
                onInput={handleInput}
                onScroll={handleScroll}
                rows={rows}
                value={value}
                onChange={(event: any)=>{
                    handleTextAreaChange(event);
                    if (autoHeight) handleHeight();
                    handleResize();
                    if (onChange) onChange(event);
                }}
                {...props}
            />
            <BackSide ref={backsideRef} style={{height: `${height}px`, maxHeight: `${height}px`}}/>
        </Wrapper>
    );
};

export default MessageEditor;
