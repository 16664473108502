import styled from "styled-components";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export const StyledAutocomplete = styled(ReactSearchAutocomplete)`
  width: 234px;
  min-width: 50px;
  box-sizing: border-box;
  &>.wrapper:first-child {
    box-sizing: border-box;
    outline: none;
    &>div {
      &>input {
        &::placeholder {
          font-style: italic;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
`;