import React, {FC} from 'react';
import BaseModal from "./BaseModal";
import errorIcon from '../../../assets/images/icons/icon-error-red.svg';
import successIcon from '../../../assets/images/icons/icon-success-green.svg';
import {
    AlertHeader,
    Img,
    Error,
    Success,
    AlertContent,
    AlertFooter,
    SuccessMessage,
    ErrorMessage,
    ErrorsList,
    ErrorItem,
    OkBtn,
} from "./styled";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {hideAlert} from "../../../store/alert/alertSlice";

const AlertModal: FC = ({...props}) => {

    const dispatch = useAppDispatch();
    const {isOpened, type, message, errors, cb} = useAppSelector(state => state.alert);

    const handleOnClose = () => {
        dispatch(hideAlert());
        if (cb) cb();
    }

    return (
        <BaseModal
            isOpen={isOpened}
            onClose={handleOnClose}
            maxWidth={400}
            {...props}
        >
            <AlertHeader>
                {type === 'success' && (
                    <>
                        <Img src={successIcon} alt={'Error'}/>
                        <Success>Success</Success>
                    </>

                )}
                {type === 'error' && (
                    <>
                        <Img src={errorIcon} alt={'Error'}/>
                        <Error>Error</Error>
                    </>
                )}
            </AlertHeader>
            <AlertContent>
                {type === 'success' && (
                    <SuccessMessage dangerouslySetInnerHTML={{__html: message}}/>
                )}
                {type === 'error' && (
                    <>
                        <ErrorMessage dangerouslySetInnerHTML={{__html: message}}/>
                        {errors && (
                            <ErrorsList>
                                {errors.map((item: string, key: number) => (
                                <ErrorItem key={key}>
                                    {item}
                                </ErrorItem>
                            ))}
                            </ErrorsList>
                        )}
                    </>
                )}
            </AlertContent>
            <AlertFooter>
                <OkBtn
                    onClick={handleOnClose}
                    title={'OK'}
                    width={170}
                />
            </AlertFooter>
        </BaseModal>
    );
};

export default AlertModal;
