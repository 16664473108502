import React, {FC} from 'react';
import {ReactSearchAutocompleteProps} from "react-search-autocomplete/dist/components/ReactSearchAutocomplete";
import {defaultTheme} from "../../../themes/main";
import {StyledAutocomplete} from "./styled";

const Autocomplete: FC<ReactSearchAutocompleteProps<any>> = (props) => {

    const formatResult = (item: any) => {
        return (
            <>
                <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
            </>
        )
    }

    return (
        <StyledAutocomplete
            inputDebounce={300}
            maxResults={15}
            maxLength={20}
            autoFocus
            formatResult={formatResult}
            styling={{
                height: "36px",
                border: "1px solid rgba(38, 38, 38, 0.2)",
                borderRadius: "4px",
                boxShadow: "none",
                color: defaultTheme.greyTextColor,
                fontSize: "14px",
                fontFamily: '"Roboto", sans-serif',
                iconColor: defaultTheme.greyColor,
                zIndex: 2,
                placeholderColor: "#a4b1b4",
                // hoverBackgroundColor: "lightgreen",
                // backgroundColor: "white",
                // backgroundColor: "white",
                // lineColor: "lightgreen",
                // clearIconMargin: "3px 8px 0 0",
            }}
            {...props}
        />
    );
};

export default Autocomplete;
