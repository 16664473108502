import React, {FC} from 'react';
import {Props as SelectProps} from "react-select/dist/declarations/src";
import {defaultTheme} from "../../../themes/main";
import {StyledSelect} from "./styled";

export interface Props extends SelectProps {
    value: string;
    options: {value: string, label: string}[];
    onChange: any;
    error?: any;
}

const customStyles = {
    singleValue: (base: any) => ({
        ...base,
        fontSize: '14px',
        color: defaultTheme.greyTextColor
    }),
    placeholder: (defaultStyles: any) => {
        return {
            ...defaultStyles,
            color: '#a4b1b4',
            fontSize: '14px',
            fontStyle: 'italic',
            opacity: 0.5,
        }
    },
    control: (base: any) => ({
        ...base,
        border: '1px solid #C4C4C4',
        boxShadow: 'none',
        height: '40px',
    }),
    // input: (base: any) => ({
    //     ...base,
    //     color: "blue"
    // })
};

const Select: FC<Props> = ({onChange, value, options, error = '', ...props}) => {
    const getValue = options.find(item => item.value === value) || null;
    const handleOnChange = (e: any) => onChange(e.value);

    return (
        <StyledSelect
            value={getValue}
            options={options}
            onChange={handleOnChange}
            styles={customStyles}
            {...props}
        />
    );
};

export default Select;
