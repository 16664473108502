import styled from "styled-components";
import ReactInputMask from 'react-input-mask';

export const StyledInputMask = styled(ReactInputMask)`
  margin: 0;
  position: relative;
  height: 40px;
  width: 100%;
  min-width: 50px;
  box-sizing: border-box;
  padding: 14px;
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.greyTextColor};

  &:focus{
    outline: none;
  }

  &::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #a4b1b4;
  }
`;

export const Error = styled.div`
  position: absolute;
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.errorColor};
`;