import styled from "styled-components";
import {Autocomplete, Button, SmsMessage} from "../../controls";

export const Wrapper = styled.div`
  display: flex;
`;

/// Users ///

export const DialogsPanel = styled.div`
  width: 420px;
  min-width: 420px;
  margin-right: 28px;
  min-height: 802px;
  max-height: 802px;
  background-color: #E3F1F4;
  border-radius: 5px;

  @media screen and (max-width: 1037px){
    display: none;
  }
`;

export const DialogSearch = styled.div`
  margin: 30px 30px 36px 22px;
`;

export const Dialogs = styled.div`
  margin-left: 22px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 665px;

  /* Scrollbar styling */
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #76AFB8 #e1e1e1;
  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #76AFB8;
  }
  /* End scrollbar styling*/
`;

export const DialogItem = styled.div<{unreadCount: number, isSelected: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 346px;
  min-height: 47px;
  max-height: 47px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.isSelected ? props.theme.lightGreyColor : (props.unreadCount ? props.theme.dialogNewMessagesTextColor : props.theme.greenColor)};
  background-color: ${(props) => props.isSelected ? "white" : (props.unreadCount ? props.theme.dialogNewMessagesBackgroundColor : 'white')};
  color: ${(props) => props.isSelected ? props.theme.lightGreyColor : (props.unreadCount ? props.theme.dialogNewMessagesTextColor : props.theme.greenColor)};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.isSelected ? props.theme.lightGreyColor : (props.unreadCount ? props.theme.dialogNewMessagesTextColorBold : props.theme.greenColorHovered)};
    border: 1px solid ${(props) => props.isSelected ? props.theme.lightGreyColor : props.theme.greenColorHovered};  
  }
`;

export const DialogRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 24px;
`;

export const DialogNotifications = styled.div<{isSelected: boolean}>`
  display: flex;
  width: 44px;
  height: 22px;
  color: #FFFFFF;
  background-color: rgba(255, 101, 82, ${(props) => props.isSelected ? '0.4' : '1'});
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  min-width: 44px;

  &:before {
    display: flex;
    margin-right: 5px;
    content: url('data:image/svg+xml,<svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.49944 0C2.56645 0 0.999442 1.567 0.999442 3.5V4.72614C0.999442 5.34489 0.602654 5.886 0.279617 6.41373C0.10224 6.70349 0 7.04425 0 7.40888C0 8.33054 0.658485 9.12129 1.57141 9.24788C2.47801 9.37359 3.61084 9.50003 4.5 9.50003C5.38916 9.50003 6.52199 9.37359 7.42859 9.24788C8.34152 9.12129 9 8.33054 9 7.40888C9 7.04406 8.89766 6.70315 8.72012 6.41329C8.39673 5.88531 7.99944 5.34392 7.99944 4.72477V3.5C7.99944 1.567 6.43244 0 4.49944 0Z" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.98452 10.4781C5.79049 10.3491 5.52863 10.4018 5.39962 10.5958C5.20576 10.8874 4.87553 11.0781 4.50093 11.0781C4.12634 11.0781 3.7961 10.8874 3.60224 10.5958C3.47324 10.4018 3.21137 10.3491 3.01735 10.4781C2.82333 10.6071 2.77062 10.869 2.89963 11.063C3.24323 11.5798 3.83205 11.9218 4.50093 11.9218C5.16982 11.9218 5.75864 11.5798 6.10224 11.063C6.23124 10.869 6.17854 10.6071 5.98452 10.4781Z" fill="white"/></svg>');
  }
`;

export const DialogSelected = styled(DialogItem)`
  color: ${(props) => props.theme.yellowColor};
  border: 1px solid ${(props) => props.theme.buttonYellow};
  &:hover {
    color: ${(props) => props.theme.yellowColor};
    border: 1px solid ${(props) => props.theme.buttonYellow};
  }
`;

export const NoDialogs = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.greenColor};
`;

export const DialogName = styled.div`
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DialogCompany = styled.div`
  font-weight: 500;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
`;

export const DialogDate = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  min-width: 120px;
  text-align: right;
`;


/// Messages ///

export const NotSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0 10px;
  height: 230px;
  width: 100%;
  background: ${(props) => props.theme.whiteColor};
  color: ${(props) => props.theme.greenColor};
  border: 1px solid ${(props) => props.theme.greenColor};
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
`;

export const Messages = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${(props) => props.theme.greenColor};
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
`;

export const MessagesList = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;

  /* Scrollbar styling */
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: #76AFB8 #e1e1e1;
  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #76AFB8;
  }
  /* End scrollbar styling*/
`;

/// Messages Header ///

export const MessagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  padding-left: 32px;
  padding-right: 32px;
  min-height: 100px;
  height: 100px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.whiteColor};
  background: ${(props) => props.theme.greenColor};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const MessagesHeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MessagesHeaderName = styled.div`
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;

  &>span {
    font-weight: 400;  
  }
`;

export const MessagesHeaderDate = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
`;

/// Message ///

export const Message = styled.div`
  display: flex;
  align-items: start;
  width: 70%;
  margin-bottom: 30px;
  color: ${(props) => props.theme.blackTextColor};
`;

export const MessageBody = styled.div`
  padding: 16px 16px 12px 16px;
`;

export const MessageBodyText = styled.div`
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  word-wrap: normal;
  word-break: break-word;
`;

export const MessageBodyFooter = styled.div`
  text-align: right;
  font-weight: 700;
  font-size: 13px;
  
  & div:last-child {
    margin-top: 6px;
    font-weight: 400;
    text-transform: capitalize;
  }
`;

export const MessageAvatar = styled.img`

`;

export const MessageOutcome = styled(Message)`
  justify-content: flex-end;
  flex-direction: row;
  margin-left: auto;
  margin-right: 32px;

  @media screen and (max-width: 500px){
    margin-right: 15px;
  }

  ${MessageBody} {
    margin-right: 20px;
    background: ${(props) => props.theme.massageOutcomeColor};
    border-radius: 15px 0 15px 15px;
  }
`;

export const MessageOutcomeError = styled(MessageOutcome)`
  ${MessageBody} {
    background: ${(props) => props.theme.massageOutcomeErrorColor};
  }
`;

export const MessageIncome = styled(Message)`
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-right: auto;
  margin-left: 32px;

  @media screen and (max-width: 500px){
    margin-left: 15px;
  }
  
  ${MessageBody} {
    margin-left: 20px;
    background: ${(props) => props.theme.massageIncomeColor};
    border-radius: 0 15px 15px 15px;
  }
`;

export const NoMessages = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  margin: 20px 0 20px;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.greenColor};
`;

/// Messages Footer ///

export const MessagesFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

export const OptOutBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const OptOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: rgba(255, 222, 218, 0.5);
  font-weight: 600;
  font-size: 12px;
  color: #FF6552;
  border-radius: 15px;
`;

export const ReplyTo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 32px 15px 32px;
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.blackTextColor};
  
  &>img {
    margin-left: 15px;
  }
  
  &>span {
    margin-left: 5px;
    font-weight: 400;
  }
`;

export const MessageText = styled.div`
  margin: 0 32px 15px 32px;  
  min-height: 100px;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin: 26px 32px 32px 32px;
`;

export const StyledButton = styled(Button)`
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 372px;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;




