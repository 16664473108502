const timezonesList = [
    {
        abbr: 'EST5EDT', title: 'Eastern Time', offset: '-05:00 / -04:00'
    },
    {
        abbr: 'CST6CDT', title: 'Central Time', offset: '-06:00 / -05:00'
    },
    {
        abbr: 'MST7MDT', title: 'Mountain Time', offset: '-07:00 / -06:00'
    },
    {
        abbr: 'PST8PDT', title: 'Pacific Time', offset: '-08:00 / -07:00'
    },
    // {
    //     abbr: 'AKT', title: 'Alaska Time', offset: '-09:00 / -08:00'
    // },
    // {
    //     abbr: 'HT', title: 'Hawaii Time', offset: '-10:00 / -09:00'
    // },
]

export default timezonesList;