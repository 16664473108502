import React, {FC} from 'react';
import BaseModal from "./BaseModal";
import warningIcon from '../../../assets/images/icons/icon-warning-yellow.svg';
import {
    AlertHeader,
    Img,
    Warning,
    ConfirmationAlertFooter,
    ConfirmBtn,
    CancelBtn,
} from "./styled";

interface Props {
    isOpen: boolean,
    text?: string,
    onClose: () => void,
    onConfirm: () => void,
}

const ConfirmationModal: FC<Props> = ({isOpen = false, text= 'Confirmation', onClose, onConfirm, ...props}) => {
    return (
        <BaseModal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={400}
            {...props}
        >
            <AlertHeader>
                <Img src={warningIcon} alt={'Error'}/>
                <Warning>{text}</Warning>
            </AlertHeader>
            <ConfirmationAlertFooter>
                <ConfirmBtn
                    onClick={onConfirm}
                    title={'CONFIRM'}
                    width={150}
                />
                <CancelBtn
                    onClick={onClose}
                    title={'CANCEL'}
                    width={150}
                />
            </ConfirmationAlertFooter>
        </BaseModal>
    );
};

export default ConfirmationModal;
