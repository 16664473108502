import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';
import {useAppSelector} from "../hooks/global";
import { ROUTES } from './routes';
import { ThemeProvider } from "styled-components";
import { defaultTheme } from '../themes/main';
import {MainLayout, SimpleLayout} from "../components/common";
import {
    SignIn,
    Customers,
    NotFound,
    Customer,
    SmsPanel,
    SmsActivity,
    Import,
    Campaigns,
    Campaign,
} from '../components/pages';

const Router = () => {
  const {accessToken, role} = useAppSelector(state => state.auth);
  const isAuthenticated  = !!localStorage.getItem('access_token') || !!accessToken;
  const userRole  = localStorage.getItem('role') || role;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Routes>
          <Route path={ROUTES.SIGN_IN} element={<SimpleLayout><SignIn /></SimpleLayout>} />
            {(isAuthenticated) && (
                <>
                  <Route path="/" element={<Navigate to={ROUTES.CUSTOMERS} />} />
                  <Route path={ROUTES.CUSTOMERS} element={<MainLayout><Customers /></MainLayout>} />
                  <Route path={ROUTES.CUSTOMER} element={<MainLayout><Customer /></MainLayout>} />
                  <Route path={ROUTES.SMS_PANEL} element={<MainLayout><SmsPanel /></MainLayout>} />
                  <Route path={ROUTES.SMS_ACTIVITY} element={<MainLayout><SmsActivity /></MainLayout>} />
                  <Route path={ROUTES.IMPORT} element={<MainLayout><Import /></MainLayout>} />
                  <Route path={ROUTES.CAMPAIGNS} element={<MainLayout><Campaigns/></MainLayout>} />
                  <Route path={ROUTES.CAMPAIGN_UPDATE} element={<MainLayout><Campaign/></MainLayout>} />
                  <Route path={ROUTES.CAMPAIGN_CREATE} element={<MainLayout><Campaign/></MainLayout>} />
                  {/*{userRole === 'admin' && (*/}
                  {/*    <>*/}
                  {/*      <Route path={ROUTES.USERS} element={<Users />} />*/}
                  {/*      <Route path={ROUTES.USER_CREATE} element={<UserCreate />} />*/}
                  {/*      <Route path={ROUTES.USER_EDIT} element={<UserEdit />} />*/}
                  {/*    </>*/}
                  {/*)}*/}
                </>
            )}
        <Route path="/" element={<Navigate to={ROUTES.SIGN_IN} replace/>} />
        <Route path="/sign-in" element={<Navigate to={ROUTES.SIGN_IN} />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </ThemeProvider>
  );
};

export default Router;
