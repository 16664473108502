import styled from "styled-components";
import {defaultTheme} from "../../../themes/main";

export const customStyles = {
    table: {
        style: {
            fontFamily: defaultTheme.fontFamily,
        },
    },
    headRow: {
        style: {
            minHeight: '48px',
            backgroundColor: '#EDF6F8',
            fontSize: '14px',
            fontWeight: 700,
        },
    },
    headCells: {
        style: {
            paddingLeft: '12px',
            paddingRight: '12px',
        },
    },
    cells: {
        style: {
            paddingLeft: '12px',
            paddingRight: '12px',
        },
    },
    rows: {
        style: {
            fontSize: '12px',
            fontWeight: 400,
            minHeight: '40px',
        },
    },
};

export const customTheme = {
    text: {
        primary: defaultTheme.blackTextColor,
    },
    striped: {
        text: defaultTheme.blackTextColor,
    },
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  @media screen and (max-width: 570px){
    flex-direction: column-reverse;
  }
`;

export const Counter = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme.lightGreyColor};
`;

export const Buttons = styled.div`
  display: flex;
  padding-bottom: 15px;
  height: 32px;
`;

export const StyledButton = styled.button<any>`
  border: 1px solid #7CB5BE;
  margin-left: 4px;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${(props) => props.theme.greenColor};
  background-color: white;
  font-weight: 500;
  font-size: 12px;
  width: 80px;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.buttonYellow};
    color: ${(props) => props.theme.whiteColor};
  }
  
  &:disabled {
    background-color: #f3f3f3;
    color: #cbcbcb;
    border: none;
  }
  
  ${(props) => props.$active && {
    backgroundColor: props.theme.buttonYellow,
    color: props.theme.whiteColor, 
    border: 'none',
  }}
`;

export const SmallButton = styled(StyledButton)`
  width: 32px;
`;

export const Arrow = styled.img`
  top: 1px;
  position: relative;
`;