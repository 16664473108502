import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import moment from "moment";
import {useAppDispatch, useAppSelector} from '../../../hooks/global';
import {
    fetchCustomers,
    fetchDeleteCustomer,
    fetchSearch,
    resetAccounts,
    resetCustomers,
    resetDirectors,
    setFilterParams
} from "../../../store/customer/customerSlice";
import {DataTable, PageContent, PageContentContainer, PageHeader, SearchPanel, Spinner} from "../../common";
import {Button, Label} from "../../controls";
import {defaultTheme} from "../../../themes/main";
import searchIcon from "../../../assets/images/icons/search-icon.svg";
import AutocompleteMUI from "../../controls/AutocompleteMUI/AutocompleteMUI";
import ConfirmationModal from "../../common/Modal/ConfirmationModal";
import {GoTrashcanStyled} from "./styled";

const Customers: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const didMountRef = useRef(false);
    const {customers, search, listFilters, pagination, isLoading, isAutocompleteLoading} = useAppSelector(state => state.customer);
    const keepFilters = location.state?.keepFilters || false;
    const [account, setAccount] = useState<string>('');
    const [customer, setCustomer] = useState<string>('');
    const [director, setDirector] = useState<string>('');
    const [customerIdToDelete, setCustomerIdToDelete] = useState(null);

    const columns = [
        {
            name: 'CUSTOMER NAME',
            alias: 'customer',
            selector: (row: any) => row.customer,
            sortable: true,
            width: '190px',
            cell: (row: any) =><div>
                <Link to={`/customer/${row.deceased_id}`}>
                    <span style={{color: defaultTheme.greenColor}}>{`${row.customer_first_name} ${row.customer_last_name}`}</span>
                </Link>
            </div>,
            style: {
                color: defaultTheme.greenColor,
            }
        },
        {
            name: 'ACCOUNT',
            alias: 'account',
            selector: (row: any) => row.company+' - '+row.company_location,
            sortable: true,
            width: '190px',
        },
        {
            name: 'PHONE',
            alias: 'phone',
            selector: (row: any) => row.cell_phone,
            sortable: true,
            wrap: true,
            width: '150px',
        },
        {
            name: 'BIRTH DATE',
            alias: 'dob',
            selector: (row: any) => row.dob ? moment(row.dob).format('MM/DD/YYYY') : '',
            sortable: true,
            width: '120px',
        },
        {
            name: 'DEATH DATE',
            alias: 'dod',
            selector: (row: any) => row.dod ? moment(row.dod).format('MM/DD/YYYY') : '',
            sortable: true,
            width: '130px',
        },
        {
            name: 'DIRECTOR NAME',
            alias: 'director',
            selector: (row: any) => row.director,
            sortable: true,
        },
        {
            name: 'DECEASED FULL NAME',
            alias: 'deceased',
            selector: (row: any) => `${row.deceased_first_name} ${row.deceased_last_name}`,
            sortable: true,
        },
        {
            width: '55px',
            cell: (row: any) => (
                <div onClick={() => setCustomerIdToDelete(row.deceased_id)}>
                    <GoTrashcanStyled/>
                </div>
            ),
        },
    ];

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) {
            handleReset();
        } else {
            setAccount(listFilters.account);
            setCustomer(listFilters.customer);
            setDirector(listFilters.director);
        }
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchCustomers(listFilters));
        }
    }, [listFilters]);

    useEffect(() => {
        if (!account) dispatch(resetAccounts());
        if (!customer) dispatch(resetCustomers());
        if (!director) dispatch(resetDirectors());
    }, [search]);

    const handleReset = () => {
        setAccount('');
        setCustomer('');
        setDirector('');
        dispatch(setFilterParams({...listFilters, account: '', customer: '', director: '', page: 1}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const handleAutocompleteLoad = (alias: string, query: string) => {
        if (alias === 'customer') {
            dispatch(resetCustomers());
            setCustomer(query);
        } else if (alias === 'account') {
            dispatch(resetAccounts());
            setAccount(query);
        } else if (alias === 'director') {
            dispatch(resetDirectors());
            setDirector(query);
        }

        if (query && query.length > 1) {
            dispatch(fetchSearch({alias, query}));
        } else if (!query) {
            dispatch(setFilterParams({...listFilters, [alias]: query, page: 1}));
        }
    };

    const handleAutocompleteSelect = (alias: string, value: string, reason: string, key: string) => {
        if (key !== 'Enter' || key !== undefined) {
            if (alias === 'customer' && value !== '') setCustomer(value);
            else if (alias === 'account') setAccount(value);
            else if (alias === 'director') setDirector(value);
            dispatch(setFilterParams({...listFilters, [alias]: value, page: 1}));

            if (reason === 'clear') {
                handleAutocompleteReset(alias);
            }
        }
    };

    const handleAutocompleteReset = (alias: string) => {
        if (alias === 'customer') {
            dispatch(resetCustomers());
            setCustomer('');
        } else if (alias === 'account') {
            dispatch(resetAccounts());
            setAccount('');
        } else if (alias === 'director') {
            dispatch(resetDirectors());
            setDirector('');
        }
        dispatch(setFilterParams({...listFilters, [alias]: '', page: 1}));
    };

    const handleSearch = () => {
        dispatch(setFilterParams({...listFilters, account, customer, director, page: 1}));
    };

    const handleDelete = (id: number | null) => {
        if (id) {
            dispatch(fetchDeleteCustomer(id)).then(resp => {
                setCustomerIdToDelete(null);
                dispatch(fetchCustomers(listFilters));
            });
        }
    }


    return (
        <PageContentContainer>
            <PageHeader>
                <Spinner isLoading={isLoading || isAutocompleteLoading}/>
                <SearchPanel
                    title={'Customers'}
                >
                    <Label>Search:</Label>
                    <AutocompleteMUI
                        isLoading={isAutocompleteLoading}
                        onChange={(e:any, reason:string)=>handleAutocompleteSelect('customer', e.target.textContent, reason, e.key)}
                        onSearch={(e: any)=>handleAutocompleteLoad('customer', e?.target?.value || '')}
                        items={search.customers}
                        inputValue={customer}
                        placeholder={'Customer'}
                    />
                    <AutocompleteMUI
                        isLoading={isAutocompleteLoading}
                        onChange={(e:any, reason:string)=>handleAutocompleteSelect('account', e.target.textContent, reason, e.key)}
                        onSearch={(e: any)=>handleAutocompleteLoad('account', e?.target?.value || '')}
                        items={search.accounts}
                        inputValue={account}
                        placeholder={'Accounts'}
                    />
                    <AutocompleteMUI
                        isLoading={isAutocompleteLoading}
                        onChange={(e:any, reason:string)=>handleAutocompleteSelect('director', e.target.textContent, reason, e.key)}
                        onSearch={(e: any)=>handleAutocompleteLoad('director', e?.target?.value || '')}
                        items={search.directors}
                        inputValue={director}
                        placeholder={'Director'}
                    />
                    {/*<Autocomplete*/}
                    {/*    items={search.customers}*/}
                    {/*    placeholder={'Customer'}*/}
                    {/*    // inputSearchString={accountDefault}*/}
                    {/*    onSearch={(q: string)=>handleAutocompleteLoad('customer', q)}*/}
                    {/*    onSelect={(res: any)=>handleAutocompleteSelect('customer', res?.name)}*/}
                    {/*    onClear={()=>handleAutocompleteReset('customer')}*/}
                    {/*/>*/}
                    {/*<Autocomplete*/}
                    {/*    items={search.accounts}*/}
                    {/*    placeholder={'Account'}*/}
                    {/*    onSearch={(q: string)=>handleAutocompleteLoad('account', q)}*/}
                    {/*    onSelect={(res: any)=>handleAutocompleteSelect('account', res?.name)}*/}
                    {/*    onClear={()=>handleAutocompleteReset('account')}*/}
                    {/*/>*/}
                    {/*<Autocomplete*/}
                    {/*    items={search.directors}*/}
                    {/*    placeholder={'Director'}*/}
                    {/*    onSearch={(q: string)=>handleAutocompleteLoad('director', q)}*/}
                    {/*    onSelect={(res: any)=>handleAutocompleteSelect('director', res?.name)}*/}
                    {/*    onClear={()=>handleAutocompleteReset('director')}*/}
                    {/*/>*/}
                    <Button
                        onClick={handleSearch}
                        color={'yellow'}
                        width={44}
                        title={
                            <img src={searchIcon} alt={''}/>
                        }
                    />
                </SearchPanel>
            </PageHeader>
            <PageContent>
                <DataTable
                    data={customers}
                    columns={columns}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </PageContent>
            <ConfirmationModal
                isOpen={!!customerIdToDelete}
                text={'Do you really want to delete the Customer along with all the data associated with it?'}
                onConfirm={()=>{handleDelete(customerIdToDelete)}}
                onClose={()=>setCustomerIdToDelete(null)}
            />
        </PageContentContainer>
    );
};

export default Customers;
