import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 10px 0 10px 0;
  border-bottom: 1px solid rgba(38, 38, 38, 0.15);
`;