import React, {FC, InputHTMLAttributes, useState} from 'react';
import {
    Container,
    Error,
    EyeImg,
    SearchDiv,
    SearchImg,
    StyledInput,
    StyledPasswordInput,
    StyledSearchInput,
    Wrapper
} from './styled';
import eyeOpen from '../../../assets/images/icons/eye-open.svg'
import eyeOff from '../../../assets/images/icons/eye-off.svg'
import searchIcon from '../../../assets/images/icons/search.svg'

interface InputProps extends InputHTMLAttributes<HTMLElement> {
    className?: string;
    type?: string;
    inputSize?: string;
    error?: any;
    width?: number;
    height?: number;
}

const Input: FC<InputProps> = ({className, type = 'text', inputSize = 'md', width, height, ...props}) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <Wrapper className={className}>
            <Container inputSize={inputSize} width={width}>
                {(type==='text') && (
                    <StyledInput
                        type={type}
                        {...props}
                    />
                )}
                {(type==='password') && (
                    <>
                        <StyledPasswordInput
                            type={showPassword ? 'text' : 'password'}
                            {...props}
                        />
                        <EyeImg src={showPassword ? eyeOff : eyeOpen} onClick={()=>setShowPassword(!showPassword)}/>
                    </>
                )}
                {(type==='search') && (
                    <SearchDiv>
                        <SearchImg src={searchIcon}/>
                        <StyledSearchInput
                            type={'text'}
                            {...props}
                        />
                    </SearchDiv>
                )}
            </Container>
            {!!props.error && props.error.message && (
                <Error className={'errorMsg'}>
                    {`${props.error.message}`}
                </Error>
            )}
        </Wrapper>
    );
};
export default Input;
