import styled from "styled-components";
import {Button, Select, InputMask} from "../../controls";
import Input from "../../controls/Input/Input";
import {Error} from "../../controls/Input/styled";

export const PageGroup = styled.div`
  display: flex;
  width: 100%;
  color: ${(props) => props.theme.blackTextColor};
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin: 18px 32px 18px 32px;
  justify-content: space-between;
  width: 100%;
`;

export const RightBtnsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
`;


// Form Styles //

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  min-width: 650px;

  @media screen and (max-width: 1150px){
    min-width: 0;
  } 
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
`;

export const CustomerName = styled.div`
  margin-top: 15px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.greenColor};
`;

export const FormGroup = styled.div`
  margin-top: 50px;
`;

export const FormGroupRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FormGroupCol = styled.div`
  position: relative;
  display: flex;
  margin-right: 40px;
  align-items: center;
  margin-bottom: 24px;
`;

export const HoverText = styled.div`
  position: absolute;
  top: 42px;
  left: 120px;
  padding: 5px 10px;
  background-color: #f8eac1;
  border-radius: 4px;
  z-index: 2;
`;

export const FormGroupTitle = styled.div`
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 18px;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const FormLabel = styled.div`
  width: 100px;
  font-weight: 700;
  font-size: 14px;
  margin-right: 20px;
`;

export const FormInput = styled(Input)`
  ${Error} {
    position: absolute;
  }
`;

export const FormInputMask = styled(InputMask)`
  width: 200px;
`;

export const FormSelect = styled(Select)`
  width: 200px;
`;

export const FormButton = styled(Button)`
  margin-top: -30px;
`;



// Recent Messages Styles //

export const RecentMessagesWrapper = styled.div`
  margin-top: 87px;
  width: 35%;
  min-width: 200px;

  @media screen and (max-width: 570px){
    display: none;
  }
  
`;

export const RecentMessagesTitle = styled.div`
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.greenColor};
`;

export const RecentMessagesContent = styled.div`
  border: 2px solid ${(props) => props.theme.greenColor};
  border-radius: 10px;
`;

export const RecentMessageBlock = styled.div`
  background-color: #F3F3F3;
  padding: 16px;
  margin: 10px;
  border-radius: 10px;
;
`;

export const RecentMessageBlockText = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

export const RecentMessageBlockDate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-weight: 400;
  font-size: 12px;
`;

export const RecentMessageBlockButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 18px 0 18px 0;
`;

export const RecentMessageNoMessages = styled.div`
  display: flex;
  height: 100px;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

export const OptOutBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const OptOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: rgba(255, 222, 218, 0.5);
  font-weight: 600;
  font-size: 12px;
  color: #FF6552;
  border-radius: 15px;
`;