import * as React from 'react';
import {useEffect, useState} from "react";
import DataTableComponent, {createTheme} from 'react-data-table-component';
import {PaginationComponentProps, RowState, TableStyles} from "react-data-table-component/dist/src/DataTable/types";
import {Row} from '../../../types';
import {customStyles, customTheme} from "./styled";
import Pagination from "./Pagination";

export interface OrderParams {
    sortedRows: any;
    order_by: any;
    order_dir: string;
}

type Props<T = Row> = {
    title?: string;
    data: Array<T>;
    columns: Array<any>;
    currentPage?: number;
    lastPage?: number;
    from?: number;
    to?: number;
    striped?: boolean;
    sortServer?: boolean;
    pagination?: boolean;
    selectableRows?: boolean;
    clearSelectedRows?: boolean;
    paginationPerPage?: number;
    paginationServer?: boolean;
    infinityPagination?: boolean;
    paginationTotalRows?: number;
    paginationResetDefaultPage?: boolean;
    onChangeRowsPerPage?: (currentPage: number, currentRowsPerPage: number) => void;
    onChangePage?: (page: number, totalRows?: number) => void;
    onChangeSelected?: (result: { allSelected: boolean; selectedCount: number; selectedRows: any[]}) => void;
    conditionalRowStyles?: any;
    onSort?: (column: string, sortDirection: string) => void;
    selectableRowSelected?: RowState<T>;
    customStyles?: TableStyles;
};

const DataTable = <T, >({
        title,
        striped = true,
        pagination = true,
        sortServer = true,
        paginationPerPage = 25,
        paginationServer = true,
        clearSelectedRows = false,
        selectableRows = false,
        currentPage = 1,
        lastPage = 1,
        from = 1,
        to = 1,
        paginationTotalRows = 1,
        infinityPagination,
        onChangeSelected,
        onChangePage,
        selectableRowSelected,
        onSort,
        data,
        customStyles,
        ...props
    }: Props<T>) => {

    const [currentPage_, setCurrentPage] = useState(currentPage);

    createTheme('customTheme', customTheme, 'default');

    useEffect(() => {
        setCurrentPage(currentPage)
    }, [currentPage]);

    if (paginationServer === false) {
        paginationTotalRows = data.length;
        lastPage = Math.ceil(data.length / paginationPerPage);
        from=paginationPerPage*(currentPage_-1) + 1;
        to=paginationPerPage*currentPage_;
    }

    return (
        <DataTableComponent
            customStyles={customStyles}
            theme={'customTheme'}
            noHeader={true}
            data={data}
            title={<h2>{title}</h2>}
            striped={striped}
            className={'dataTable'}
            paginationPerPage={paginationPerPage}
            paginationServer={paginationServer}
            sortServer={sortServer}
            pagination={pagination}
            selectableRows={selectableRows}
            onSelectedRowsChange={onChangeSelected}
            selectableRowSelected={selectableRowSelected}
            onSort={(column: any, sortDirection: string) => onSort && onSort(column.alias, sortDirection)}
            clearSelectedRows={clearSelectedRows}
            paginationComponent={(paginationProps: PaginationComponentProps) => (
                <Pagination
                    infinityPagination={infinityPagination}
                    currentPage={currentPage_}
                    rowsPerPage={paginationPerPage}
                    lastPage={lastPage}
                    rowCount={paginationTotalRows}
                    from={from}
                    to={to}
                    onChangePage={(page: number) => {
                        setCurrentPage(page)
                        onChangePage && onChangePage(page);
                        paginationProps.onChangePage(page, paginationTotalRows);
                    }}
                />
            )}
            {...props}
        />
    );
};

export default DataTable;
