import React, {FC, InputHTMLAttributes, useState} from 'react';
import {Counter, TextareaStyled, Wrapper} from './styled';

interface Props extends InputHTMLAttributes<HTMLElement> {
    name: string;
    onChange: (event:any)=>void;
}

const SmsMessage: FC<Props> = ({onChange, ...props}) => {

    const [count, setCount] = useState<number>(0);

    const handleOnChange = (event: any) => {
        const value = event.target.value;
        if (value) setCount(value.length);
        onChange(event);
    }

    return (
        <Wrapper>
            <TextareaStyled
                onChange={handleOnChange}
                {...props}
            />
            <Counter>{count}/160</Counter>
        </Wrapper>
    );
};

export default SmsMessage;
