import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import FileSaver from 'file-saver';

export const fetchStates = createAsyncThunk('general/states', async (_, {dispatch}) => {
    const response = await api.get('general/states');
    dispatch(setStates(response.data));
});

export const fetchLocations = createAsyncThunk('general/locations', async (company_id, {dispatch}) => {
    const response = await api.get('general/locations', {params: {company_id}});
    return response.data;
});

export const fetchCaseTypes = createAsyncThunk('general/case-types', async (company_id, {dispatch}) => {
    const response = await api.get('general/case-types', {params: {company_id}});
    return response.data;
});

export const fetchFileUpload = createAsyncThunk('file/upload', async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await api.post('file/upload', formData);
    return response.data;
});

interface GlobalState {
    isLoading: boolean;
    states: any[],
    locations: any[],
    caseTypes: any[],
    uploader: any
}

const initialState: GlobalState = {
    isLoading: false,
    states: [],
    locations: [],
    caseTypes: [],
    uploader: null
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload.data.resource;
        },
        resetUploader: (state) => {
            state.uploader = initialState.uploader;
        },
        fileDownload: (state, action) => {
            FileSaver.saveAs(action.payload.file, action.payload.title);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLocations.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchLocations.fulfilled, (state: any, action: any) => {
                state.locations = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchLocations.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCaseTypes.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCaseTypes.fulfilled, (state: any, action: any) => {
                state.caseTypes = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchCaseTypes.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchFileUpload.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchFileUpload.fulfilled, (state: any, action: any) => {
                state.uploader = action.payload.data;
                state.isLoading = false;
            })
            .addCase(fetchFileUpload.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(state=> {
                state.isLoading = false;
            });
    },
});

export const {setStates, resetUploader, fileDownload} = generalSlice.actions;
export default generalSlice.reducer;
