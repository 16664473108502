import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  min-height: 100px;
  height: 100%;
  padding: 10px 10px 5px 10px;
  border: 1px solid rgba(38, 38, 38, 0.2);
  border-radius: 4px;
`;

export const TextareaStyled = styled.textarea`
  margin: 0;
  display: block;
  border: none;
  outline: none;
  width: 100%;
  min-height: 100px;
  //padding: 14px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  color: ${(props) => props.theme.greyTextColor};
  //border: 1px solid rgba(38, 38, 38, 0.2);
  //border-radius: 4px;
  resize: none;
  //resize: vertical;
  overflow: hidden;
  
  &:focus{
    outline: none;
  }
  
  &::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #a4b1b4;
  }
`;

export const Counter = styled.span`
    display: inline;
    position: absolute;
    bottom: 5px;
    right: 8px;
    font-weight: 400;
    font-size: 12px;
    color: ${(props) => props.theme.greyTextColor};
`;
