import React, {FC, useEffect, useRef, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {fetchSmsActivity, setFilterParams} from "../../../store/smsActivity/smsActivitySlice";
import {Button, Label} from "../../controls";
import {DataTable, PageContent, PageContentContainer, PageHeader, SearchPanel, Spinner} from "../../common";
import {defaultTheme} from "../../../themes/main";
import searchIcon from "../../../assets/images/icons/search-icon.svg";
import moment from 'moment-timezone';
import AutocompleteMUI from "../../controls/AutocompleteMUI/AutocompleteMUI";

const columns = [
    {
        name: 'CUSTOMER',
        alias: 'customer',
        selector: (row: any) => row.customer,
        sortable: true,
        width: '160px',
        cell: (row: any) =><div>
            <Link to={`/customer/${row.deceased_id}`}>
                <span style={{color: defaultTheme.greenColor}}>{`${row.customer_first_name} ${row.customer_last_name}`}</span>
            </Link>
        </div>,
        style: {
            color: defaultTheme.greenColor,
        }
    },
    {
        name: 'FROM',
        alias: 'from_number',
        selector: (row: any) => row.from_number,
        sortable: true,
        width: '140px',
    },
    {
        name: 'TO',
        alias: 'to_number',
        selector: (row: any) => row.to_number,
        sortable: true,
        wrap: true,
        width: '140px',
        cell: (row: any) =><div>
            {row.to_number} <span style={{color: defaultTheme.greenColor}}>{row.to_operator}</span>
        </div>
    },
    {
        name: 'MESSAGE',
        alias: 'message',
        selector: (row: any) => row.message,
        wrap: true,
        sortable: true,
        cell: (row: any) =><div style={{marginTop: '6px', marginBottom: '6px'}}>{row.message}</div>
    },
    {
        name: 'DATE',
        alias: 'created_at',
        selector: (row: any) => row.created_at ? moment.utc(row.created_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY hh:mm A') : '',
        sortable: true,
        width: '160px',
    },
    {
        name: 'REASON',
        alias: 'error_message',
        selector: (row: any) => row.error_message,
        sortable: true,
        wrap: true,
        style: {
            color: defaultTheme.greenColor,
        }
    },
];

const SmsActivity: FC = () => {

    const dispatch = useAppDispatch();
    const didMountRef = useRef(false);
    const location = useLocation();
    const {items, isLoading, listFilters, pagination} = useAppSelector(state => state.smsActivity);
    const keepFilters = location.state?.keepFilters || false;
    const [searchString, setSearchString] = useState<string>('');

    useEffect(() => {
        didMountRef.current = true;
        if (!keepFilters) handleReset();
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            dispatch(fetchSmsActivity(listFilters));
        }
    }, [listFilters]);

    const handleReset = () => {
        dispatch(setFilterParams({...listFilters, page: 1, query: ''}));
    };

    const handleChangePage = (page: number) => {
        dispatch(setFilterParams({...listFilters, page}));
    };

    const handleSort = (order_by: string, order_dir: string) => {
        dispatch(setFilterParams({...listFilters, order_by, order_dir, page: 1}));
    };

    const handleSearch = () => {
        dispatch(setFilterParams({...listFilters, page: 1, query: searchString}));
    };

    const handleOnSearchChange = (s: any) => {
        const {value} = s.target;
        setSearchString(value || '');
        if (value===undefined) {
            setSearchString('');
            handleReset();
        }
    };

    return (
        <PageContentContainer>
            <PageHeader>
                <Spinner isLoading={isLoading}/>
                <SearchPanel
                    title={'SMS Activity'}
                >
                    <Label>Search:</Label>
                    <AutocompleteMUI
                        isLoading={isLoading}
                        onChange={()=>{}}
                        onSearch={handleOnSearchChange}
                        items={[]}
                        inputValue={searchString}
                        placeholder={'Search'}
                    />
                    {/*<Autocomplete*/}
                    {/*    items={[]}*/}
                    {/*    placeholder={'Search'}*/}
                    {/*    showNoResults={false}*/}
                    {/*    inputSearchString={searchString}*/}
                    {/*    onSearch={handleOnSearchChange}*/}
                    {/*    onClear={handleOnSearchReset}*/}
                    {/*/>*/}
                    <Button
                        onClick={handleSearch}
                        color={'yellow'}
                        width={44}
                        title={
                            <img src={searchIcon} alt={''}/>
                        }
                    />
                </SearchPanel>
            </PageHeader>
            <PageContent>
                <DataTable
                    data={items}
                    columns={columns}
                    currentPage={pagination.current_page}
                    paginationTotalRows={pagination.total}
                    paginationPerPage={pagination.per_page}
                    lastPage={pagination.last_page}
                    from={pagination.from}
                    to={pagination.to}
                    onChangePage={handleChangePage}
                    onSort={handleSort}
                    pagination={true}
                />
            </PageContent>
        </PageContentContainer>
    );
};

export default SmsActivity;
