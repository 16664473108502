import React, {FC} from 'react';
import {
    DialogCompany,
    DialogDate,
    DialogItem,
    DialogName,
    DialogNotifications,
    DialogRow,
} from "../styled";
import moment from "moment/moment";

type Props = {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    customer: string;
    deceased: string;
    company: string;
    location: string;
    last_message_at: string;
    isSelected?: boolean;
    unreadCount?: number;
};

const Dialog: FC<Props> = ({onClick, unreadCount = 0, isSelected = false, customer,  deceased, company, location, last_message_at, ...props}) => {
    return (
        <DialogItem onClick={onClick} unreadCount={unreadCount} isSelected={isSelected}>
            <DialogRow>
                <DialogName>{`${deceased}`}</DialogName>
                <DialogDate>{last_message_at ? moment.utc(last_message_at).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY h:mm A') : ''}</DialogDate>
            </DialogRow>
            <DialogRow>
                <DialogCompany>{`${company}`}</DialogCompany>
                {!!unreadCount && (<DialogNotifications isSelected={isSelected}>{unreadCount > 9 ? '9+' : unreadCount}</DialogNotifications>)}
            </DialogRow>
        </DialogItem>
    );
};

export default Dialog;
