import styled from "styled-components";

export const Title = styled.div`
  margin-left: 32px;
  font-weight: 700;
  font-size: 26px;
  white-space: nowrap;
`;

export const Search = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: auto;
  gap: 16px;
  margin: 18px 32px 18px 32px;
;  
  //&>:last-child {
  //  //margin-left: 16px;  
  //}
`;

export const LabelStyled = styled.label`
  display: flex;
  margin-right: 10px;
  font-weight: 400;
  font-size: 14px;

  @media screen and (max-width: 1110px){
    display: none;
  }
`;
