import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../service/api';
import {CampaignMessage, CampaignProps} from "../../types";
import {showSuccessAlert} from "../alert/alertSlice";
import moment from "moment";

export const fetchDefaultMessages = createAsyncThunk('campaign/default-messages', async (params?: any) => {
    const response = await api.get('campaign/default-messages', { params });
    return response.data;
});

export const fetchCampaigns = createAsyncThunk('campaign/list', async (params: any) => {
    const response = await api.get('campaign/list', { params });
    return response.data;
});

export const fetchCampaignById = createAsyncThunk('campaign/get', async (id: string) => {
    const response = await api.get(`campaign/${id}/get`);
    return response.data;
});

export const fetchCreateCampaign = createAsyncThunk('campaign/create', async (params: any, thunkAPI) => {
    const {data, cb} = params;
    try {
        const response = await api.post(`campaign/create`, convertDataToServer(data))
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb: () => cb(response.data.data.resource.id)}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchUpdateCampaign = createAsyncThunk('campaign/update', async (params: any, thunkAPI) => {
    const {id, data, cb} = params;
    try {
        const response = await api.post(`campaign/${id}/update`, convertDataToServer(data))
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message, cb}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

export const fetchHandleActive = createAsyncThunk('campaign/handle-active', async (params: any, thunkAPI) => {
    const {id, data} = params;
    try {
        const response = await api.post(`campaign/${id}/handle-active`, data)
        thunkAPI.dispatch(showSuccessAlert({message: response.data.message}));
        return response.data
    } catch (error: any) {
        return thunkAPI.rejectWithValue({ error: error.message })
    }}
)

interface  ListFilters {
    order_by: string,
    order_dir: string,
    page: number,
    ipp: number,
}

interface  Pagination {
    total: number,
    current_page: number,
    last_page: number,
    per_page: number,
    from: number,
    to: number,
}

interface CustomerState {
    items: Array<CampaignProps>;
    defaultMessages: Array<CampaignMessage>;
    campaign: CampaignProps | null;
    listFilters: ListFilters;
    pagination: Pagination;
    isLoading: boolean;
}

const initialState: CustomerState = {
    items: [],
    defaultMessages: [],
    campaign: null,
    listFilters: {
        order_by: 'title',
        order_dir: 'asc',
        page: 1,
        ipp: 25,
    },
    pagination: {
        total: 0,
        current_page: 1,
        last_page: 1,
        per_page: 1,
        from: 1,
        to: 1,
    },
    isLoading: false,
};

export const campaignSlice = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        setFilterParams: (state, action) => {
            state.listFilters = action.payload;
        },
        resetFilters: (state) => {
            state.listFilters = initialState.listFilters;
        },
        resetCampaign: (state) => {
            state.campaign = initialState.campaign;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchDefaultMessages.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchDefaultMessages.fulfilled, (state: any, action: any) => {
                state.defaultMessages = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchDefaultMessages.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCampaigns.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCampaigns.fulfilled, (state: any, action: any) => {
                const {data, current_page, total, last_page, ipp, per_page, from, to} = action.payload.data.resource;
                state.items = data;
                state.pagination = {
                    ...state.pagination,
                    current_page: current_page,
                    total: total,
                    last_page: last_page,
                    ipp: ipp,
                    page: current_page,
                    per_page: per_page,
                    from: from,
                    to: to,
                }
                state.isLoading = false;
                state.isAutocompleteLoading = false;
            })
            .addCase(fetchCampaigns.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCampaignById.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCampaignById.fulfilled, (state: any, action: any) => {
                state.campaign = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchCampaignById.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchCreateCampaign.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchCreateCampaign.fulfilled, (state: any, action: any) => {
                state.campaign = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchCreateCampaign.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchUpdateCampaign.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchUpdateCampaign.fulfilled, (state: any, action: any) => {
                state.campaign = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchUpdateCampaign.rejected, state => {
                state.isLoading = false;
            })
            .addCase(fetchHandleActive.pending, state => {
                state.isLoading = true;
            })
            .addCase(fetchHandleActive.fulfilled, (state: any, action: any) => {
                state.campaign = action.payload.data.resource;
                state.isLoading = false;
            })
            .addCase(fetchHandleActive.rejected, state => {
                state.isLoading = false;
            })
            .addDefaultCase(() => {
            });
    },
});

const convertDataToServer = (data: any) => {
    return {
        ...data,
        messages: data.messages.map((item: any) => {
            return {
                ...item,
                date: item.date ? moment(item.date).format('YYYY-MM-DD') : null,
                time: item.time ? moment(item.time).format('HH:mm') : null,
            }
        })
    };
}


export const { setFilterParams, resetFilters, resetCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
